/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-row {
  display: flex;
  flex-flow: row wrap;
}
.ant-row::before,
.ant-row::after {
  display: flex;
}
.ant-row-no-wrap {
  flex-wrap: nowrap;
}
.ant-row-start {
  justify-content: flex-start;
}
.ant-row-center {
  justify-content: center;
}
.ant-row-end {
  justify-content: flex-end;
}
.ant-row-space-between {
  justify-content: space-between;
}
.ant-row-space-around {
  justify-content: space-around;
}
.ant-row-top {
  align-items: flex-start;
}
.ant-row-middle {
  align-items: center;
}
.ant-row-bottom {
  align-items: flex-end;
}
.ant-col {
  position: relative;
  max-width: 100%;
  min-height: 1px;
}
.ant-col-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}
.ant-col-push-24 {
  left: 100%;
}
.ant-col-pull-24 {
  right: 100%;
}
.ant-col-offset-24 {
  margin-left: 100%;
}
.ant-col-order-24 {
  order: 24;
}
.ant-col-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}
.ant-col-push-23 {
  left: 95.83333333%;
}
.ant-col-pull-23 {
  right: 95.83333333%;
}
.ant-col-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-order-23 {
  order: 23;
}
.ant-col-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.ant-col-push-22 {
  left: 91.66666667%;
}
.ant-col-pull-22 {
  right: 91.66666667%;
}
.ant-col-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-order-22 {
  order: 22;
}
.ant-col-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.ant-col-push-21 {
  left: 87.5%;
}
.ant-col-pull-21 {
  right: 87.5%;
}
.ant-col-offset-21 {
  margin-left: 87.5%;
}
.ant-col-order-21 {
  order: 21;
}
.ant-col-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.ant-col-push-20 {
  left: 83.33333333%;
}
.ant-col-pull-20 {
  right: 83.33333333%;
}
.ant-col-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-order-20 {
  order: 20;
}
.ant-col-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}
.ant-col-push-19 {
  left: 79.16666667%;
}
.ant-col-pull-19 {
  right: 79.16666667%;
}
.ant-col-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-order-19 {
  order: 19;
}
.ant-col-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}
.ant-col-push-18 {
  left: 75%;
}
.ant-col-pull-18 {
  right: 75%;
}
.ant-col-offset-18 {
  margin-left: 75%;
}
.ant-col-order-18 {
  order: 18;
}
.ant-col-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}
.ant-col-push-17 {
  left: 70.83333333%;
}
.ant-col-pull-17 {
  right: 70.83333333%;
}
.ant-col-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-order-17 {
  order: 17;
}
.ant-col-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.ant-col-push-16 {
  left: 66.66666667%;
}
.ant-col-pull-16 {
  right: 66.66666667%;
}
.ant-col-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-order-16 {
  order: 16;
}
.ant-col-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.ant-col-push-15 {
  left: 62.5%;
}
.ant-col-pull-15 {
  right: 62.5%;
}
.ant-col-offset-15 {
  margin-left: 62.5%;
}
.ant-col-order-15 {
  order: 15;
}
.ant-col-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.ant-col-push-14 {
  left: 58.33333333%;
}
.ant-col-pull-14 {
  right: 58.33333333%;
}
.ant-col-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-order-14 {
  order: 14;
}
.ant-col-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}
.ant-col-push-13 {
  left: 54.16666667%;
}
.ant-col-pull-13 {
  right: 54.16666667%;
}
.ant-col-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-order-13 {
  order: 13;
}
.ant-col-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}
.ant-col-push-12 {
  left: 50%;
}
.ant-col-pull-12 {
  right: 50%;
}
.ant-col-offset-12 {
  margin-left: 50%;
}
.ant-col-order-12 {
  order: 12;
}
.ant-col-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}
.ant-col-push-11 {
  left: 45.83333333%;
}
.ant-col-pull-11 {
  right: 45.83333333%;
}
.ant-col-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-order-11 {
  order: 11;
}
.ant-col-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.ant-col-push-10 {
  left: 41.66666667%;
}
.ant-col-pull-10 {
  right: 41.66666667%;
}
.ant-col-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-order-10 {
  order: 10;
}
.ant-col-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.ant-col-push-9 {
  left: 37.5%;
}
.ant-col-pull-9 {
  right: 37.5%;
}
.ant-col-offset-9 {
  margin-left: 37.5%;
}
.ant-col-order-9 {
  order: 9;
}
.ant-col-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.ant-col-push-8 {
  left: 33.33333333%;
}
.ant-col-pull-8 {
  right: 33.33333333%;
}
.ant-col-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-order-8 {
  order: 8;
}
.ant-col-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}
.ant-col-push-7 {
  left: 29.16666667%;
}
.ant-col-pull-7 {
  right: 29.16666667%;
}
.ant-col-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-order-7 {
  order: 7;
}
.ant-col-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}
.ant-col-push-6 {
  left: 25%;
}
.ant-col-pull-6 {
  right: 25%;
}
.ant-col-offset-6 {
  margin-left: 25%;
}
.ant-col-order-6 {
  order: 6;
}
.ant-col-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}
.ant-col-push-5 {
  left: 20.83333333%;
}
.ant-col-pull-5 {
  right: 20.83333333%;
}
.ant-col-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-order-5 {
  order: 5;
}
.ant-col-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.ant-col-push-4 {
  left: 16.66666667%;
}
.ant-col-pull-4 {
  right: 16.66666667%;
}
.ant-col-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-order-4 {
  order: 4;
}
.ant-col-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.ant-col-push-3 {
  left: 12.5%;
}
.ant-col-pull-3 {
  right: 12.5%;
}
.ant-col-offset-3 {
  margin-left: 12.5%;
}
.ant-col-order-3 {
  order: 3;
}
.ant-col-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.ant-col-push-2 {
  left: 8.33333333%;
}
.ant-col-pull-2 {
  right: 8.33333333%;
}
.ant-col-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-order-2 {
  order: 2;
}
.ant-col-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}
.ant-col-push-1 {
  left: 4.16666667%;
}
.ant-col-pull-1 {
  right: 4.16666667%;
}
.ant-col-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-order-1 {
  order: 1;
}
.ant-col-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-offset-0 {
  margin-left: 0;
}
.ant-col-order-0 {
  order: 0;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-offset-0.ant-col-rtl {
  margin-right: 0;
}
.ant-col-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto;
}
.ant-col-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%;
}
.ant-col-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}
.ant-col-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto;
}
.ant-col-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%;
}
.ant-col-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}
.ant-col-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto;
}
.ant-col-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%;
}
.ant-col-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}
.ant-col-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto;
}
.ant-col-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%;
}
.ant-col-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}
.ant-col-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto;
}
.ant-col-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%;
}
.ant-col-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}
.ant-col-push-6.ant-col-rtl {
  right: 25%;
  left: auto;
}
.ant-col-pull-6.ant-col-rtl {
  right: auto;
  left: 25%;
}
.ant-col-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}
.ant-col-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto;
}
.ant-col-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%;
}
.ant-col-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}
.ant-col-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto;
}
.ant-col-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%;
}
.ant-col-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}
.ant-col-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto;
}
.ant-col-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%;
}
.ant-col-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}
.ant-col-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto;
}
.ant-col-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%;
}
.ant-col-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}
.ant-col-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto;
}
.ant-col-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%;
}
.ant-col-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}
.ant-col-push-12.ant-col-rtl {
  right: 50%;
  left: auto;
}
.ant-col-pull-12.ant-col-rtl {
  right: auto;
  left: 50%;
}
.ant-col-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}
.ant-col-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto;
}
.ant-col-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%;
}
.ant-col-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}
.ant-col-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto;
}
.ant-col-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%;
}
.ant-col-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}
.ant-col-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto;
}
.ant-col-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%;
}
.ant-col-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}
.ant-col-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto;
}
.ant-col-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%;
}
.ant-col-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}
.ant-col-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto;
}
.ant-col-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%;
}
.ant-col-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}
.ant-col-push-18.ant-col-rtl {
  right: 75%;
  left: auto;
}
.ant-col-pull-18.ant-col-rtl {
  right: auto;
  left: 75%;
}
.ant-col-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}
.ant-col-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto;
}
.ant-col-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%;
}
.ant-col-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}
.ant-col-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto;
}
.ant-col-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%;
}
.ant-col-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}
.ant-col-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto;
}
.ant-col-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%;
}
.ant-col-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}
.ant-col-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto;
}
.ant-col-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%;
}
.ant-col-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}
.ant-col-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto;
}
.ant-col-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%;
}
.ant-col-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}
.ant-col-push-24.ant-col-rtl {
  right: 100%;
  left: auto;
}
.ant-col-pull-24.ant-col-rtl {
  right: auto;
  left: 100%;
}
.ant-col-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}
.ant-col-xs-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}
.ant-col-xs-push-24 {
  left: 100%;
}
.ant-col-xs-pull-24 {
  right: 100%;
}
.ant-col-xs-offset-24 {
  margin-left: 100%;
}
.ant-col-xs-order-24 {
  order: 24;
}
.ant-col-xs-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}
.ant-col-xs-push-23 {
  left: 95.83333333%;
}
.ant-col-xs-pull-23 {
  right: 95.83333333%;
}
.ant-col-xs-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-xs-order-23 {
  order: 23;
}
.ant-col-xs-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.ant-col-xs-push-22 {
  left: 91.66666667%;
}
.ant-col-xs-pull-22 {
  right: 91.66666667%;
}
.ant-col-xs-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-xs-order-22 {
  order: 22;
}
.ant-col-xs-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.ant-col-xs-push-21 {
  left: 87.5%;
}
.ant-col-xs-pull-21 {
  right: 87.5%;
}
.ant-col-xs-offset-21 {
  margin-left: 87.5%;
}
.ant-col-xs-order-21 {
  order: 21;
}
.ant-col-xs-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.ant-col-xs-push-20 {
  left: 83.33333333%;
}
.ant-col-xs-pull-20 {
  right: 83.33333333%;
}
.ant-col-xs-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-xs-order-20 {
  order: 20;
}
.ant-col-xs-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}
.ant-col-xs-push-19 {
  left: 79.16666667%;
}
.ant-col-xs-pull-19 {
  right: 79.16666667%;
}
.ant-col-xs-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-xs-order-19 {
  order: 19;
}
.ant-col-xs-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}
.ant-col-xs-push-18 {
  left: 75%;
}
.ant-col-xs-pull-18 {
  right: 75%;
}
.ant-col-xs-offset-18 {
  margin-left: 75%;
}
.ant-col-xs-order-18 {
  order: 18;
}
.ant-col-xs-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}
.ant-col-xs-push-17 {
  left: 70.83333333%;
}
.ant-col-xs-pull-17 {
  right: 70.83333333%;
}
.ant-col-xs-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-xs-order-17 {
  order: 17;
}
.ant-col-xs-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.ant-col-xs-push-16 {
  left: 66.66666667%;
}
.ant-col-xs-pull-16 {
  right: 66.66666667%;
}
.ant-col-xs-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-xs-order-16 {
  order: 16;
}
.ant-col-xs-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.ant-col-xs-push-15 {
  left: 62.5%;
}
.ant-col-xs-pull-15 {
  right: 62.5%;
}
.ant-col-xs-offset-15 {
  margin-left: 62.5%;
}
.ant-col-xs-order-15 {
  order: 15;
}
.ant-col-xs-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.ant-col-xs-push-14 {
  left: 58.33333333%;
}
.ant-col-xs-pull-14 {
  right: 58.33333333%;
}
.ant-col-xs-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-xs-order-14 {
  order: 14;
}
.ant-col-xs-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}
.ant-col-xs-push-13 {
  left: 54.16666667%;
}
.ant-col-xs-pull-13 {
  right: 54.16666667%;
}
.ant-col-xs-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-xs-order-13 {
  order: 13;
}
.ant-col-xs-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}
.ant-col-xs-push-12 {
  left: 50%;
}
.ant-col-xs-pull-12 {
  right: 50%;
}
.ant-col-xs-offset-12 {
  margin-left: 50%;
}
.ant-col-xs-order-12 {
  order: 12;
}
.ant-col-xs-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}
.ant-col-xs-push-11 {
  left: 45.83333333%;
}
.ant-col-xs-pull-11 {
  right: 45.83333333%;
}
.ant-col-xs-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-xs-order-11 {
  order: 11;
}
.ant-col-xs-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.ant-col-xs-push-10 {
  left: 41.66666667%;
}
.ant-col-xs-pull-10 {
  right: 41.66666667%;
}
.ant-col-xs-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-xs-order-10 {
  order: 10;
}
.ant-col-xs-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.ant-col-xs-push-9 {
  left: 37.5%;
}
.ant-col-xs-pull-9 {
  right: 37.5%;
}
.ant-col-xs-offset-9 {
  margin-left: 37.5%;
}
.ant-col-xs-order-9 {
  order: 9;
}
.ant-col-xs-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.ant-col-xs-push-8 {
  left: 33.33333333%;
}
.ant-col-xs-pull-8 {
  right: 33.33333333%;
}
.ant-col-xs-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-xs-order-8 {
  order: 8;
}
.ant-col-xs-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}
.ant-col-xs-push-7 {
  left: 29.16666667%;
}
.ant-col-xs-pull-7 {
  right: 29.16666667%;
}
.ant-col-xs-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-xs-order-7 {
  order: 7;
}
.ant-col-xs-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}
.ant-col-xs-push-6 {
  left: 25%;
}
.ant-col-xs-pull-6 {
  right: 25%;
}
.ant-col-xs-offset-6 {
  margin-left: 25%;
}
.ant-col-xs-order-6 {
  order: 6;
}
.ant-col-xs-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}
.ant-col-xs-push-5 {
  left: 20.83333333%;
}
.ant-col-xs-pull-5 {
  right: 20.83333333%;
}
.ant-col-xs-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-xs-order-5 {
  order: 5;
}
.ant-col-xs-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.ant-col-xs-push-4 {
  left: 16.66666667%;
}
.ant-col-xs-pull-4 {
  right: 16.66666667%;
}
.ant-col-xs-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-xs-order-4 {
  order: 4;
}
.ant-col-xs-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.ant-col-xs-push-3 {
  left: 12.5%;
}
.ant-col-xs-pull-3 {
  right: 12.5%;
}
.ant-col-xs-offset-3 {
  margin-left: 12.5%;
}
.ant-col-xs-order-3 {
  order: 3;
}
.ant-col-xs-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.ant-col-xs-push-2 {
  left: 8.33333333%;
}
.ant-col-xs-pull-2 {
  right: 8.33333333%;
}
.ant-col-xs-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-xs-order-2 {
  order: 2;
}
.ant-col-xs-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}
.ant-col-xs-push-1 {
  left: 4.16666667%;
}
.ant-col-xs-pull-1 {
  right: 4.16666667%;
}
.ant-col-xs-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-xs-order-1 {
  order: 1;
}
.ant-col-xs-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-xs-push-0 {
  left: auto;
}
.ant-col-xs-pull-0 {
  right: auto;
}
.ant-col-xs-offset-0 {
  margin-left: 0;
}
.ant-col-xs-order-0 {
  order: 0;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-xs-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-xs-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-xs-offset-0.ant-col-rtl {
  margin-right: 0;
}
.ant-col-xs-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto;
}
.ant-col-xs-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%;
}
.ant-col-xs-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto;
}
.ant-col-xs-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%;
}
.ant-col-xs-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto;
}
.ant-col-xs-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%;
}
.ant-col-xs-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}
.ant-col-xs-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto;
}
.ant-col-xs-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%;
}
.ant-col-xs-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto;
}
.ant-col-xs-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%;
}
.ant-col-xs-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-6.ant-col-rtl {
  right: 25%;
  left: auto;
}
.ant-col-xs-pull-6.ant-col-rtl {
  right: auto;
  left: 25%;
}
.ant-col-xs-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}
.ant-col-xs-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto;
}
.ant-col-xs-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%;
}
.ant-col-xs-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto;
}
.ant-col-xs-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%;
}
.ant-col-xs-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto;
}
.ant-col-xs-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%;
}
.ant-col-xs-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}
.ant-col-xs-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto;
}
.ant-col-xs-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%;
}
.ant-col-xs-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto;
}
.ant-col-xs-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%;
}
.ant-col-xs-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-12.ant-col-rtl {
  right: 50%;
  left: auto;
}
.ant-col-xs-pull-12.ant-col-rtl {
  right: auto;
  left: 50%;
}
.ant-col-xs-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}
.ant-col-xs-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto;
}
.ant-col-xs-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%;
}
.ant-col-xs-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto;
}
.ant-col-xs-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%;
}
.ant-col-xs-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto;
}
.ant-col-xs-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%;
}
.ant-col-xs-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}
.ant-col-xs-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto;
}
.ant-col-xs-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%;
}
.ant-col-xs-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto;
}
.ant-col-xs-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%;
}
.ant-col-xs-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-18.ant-col-rtl {
  right: 75%;
  left: auto;
}
.ant-col-xs-pull-18.ant-col-rtl {
  right: auto;
  left: 75%;
}
.ant-col-xs-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}
.ant-col-xs-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto;
}
.ant-col-xs-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%;
}
.ant-col-xs-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto;
}
.ant-col-xs-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%;
}
.ant-col-xs-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto;
}
.ant-col-xs-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%;
}
.ant-col-xs-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}
.ant-col-xs-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto;
}
.ant-col-xs-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%;
}
.ant-col-xs-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto;
}
.ant-col-xs-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%;
}
.ant-col-xs-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-24.ant-col-rtl {
  right: 100%;
  left: auto;
}
.ant-col-xs-pull-24.ant-col-rtl {
  right: auto;
  left: 100%;
}
.ant-col-xs-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}
@media (min-width: 576px) {
  .ant-col-sm-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-sm-push-24 {
    left: 100%;
  }
  .ant-col-sm-pull-24 {
    right: 100%;
  }
  .ant-col-sm-offset-24 {
    margin-left: 100%;
  }
  .ant-col-sm-order-24 {
    order: 24;
  }
  .ant-col-sm-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-sm-push-23 {
    left: 95.83333333%;
  }
  .ant-col-sm-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-sm-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-sm-order-23 {
    order: 23;
  }
  .ant-col-sm-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-sm-push-22 {
    left: 91.66666667%;
  }
  .ant-col-sm-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-sm-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-sm-order-22 {
    order: 22;
  }
  .ant-col-sm-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-sm-push-21 {
    left: 87.5%;
  }
  .ant-col-sm-pull-21 {
    right: 87.5%;
  }
  .ant-col-sm-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-sm-order-21 {
    order: 21;
  }
  .ant-col-sm-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-sm-push-20 {
    left: 83.33333333%;
  }
  .ant-col-sm-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-sm-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-sm-order-20 {
    order: 20;
  }
  .ant-col-sm-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-sm-push-19 {
    left: 79.16666667%;
  }
  .ant-col-sm-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-sm-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-sm-order-19 {
    order: 19;
  }
  .ant-col-sm-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-sm-push-18 {
    left: 75%;
  }
  .ant-col-sm-pull-18 {
    right: 75%;
  }
  .ant-col-sm-offset-18 {
    margin-left: 75%;
  }
  .ant-col-sm-order-18 {
    order: 18;
  }
  .ant-col-sm-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-sm-push-17 {
    left: 70.83333333%;
  }
  .ant-col-sm-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-sm-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-sm-order-17 {
    order: 17;
  }
  .ant-col-sm-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-sm-push-16 {
    left: 66.66666667%;
  }
  .ant-col-sm-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-sm-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-sm-order-16 {
    order: 16;
  }
  .ant-col-sm-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-sm-push-15 {
    left: 62.5%;
  }
  .ant-col-sm-pull-15 {
    right: 62.5%;
  }
  .ant-col-sm-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-sm-order-15 {
    order: 15;
  }
  .ant-col-sm-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-sm-push-14 {
    left: 58.33333333%;
  }
  .ant-col-sm-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-sm-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-sm-order-14 {
    order: 14;
  }
  .ant-col-sm-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-sm-push-13 {
    left: 54.16666667%;
  }
  .ant-col-sm-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-sm-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-sm-order-13 {
    order: 13;
  }
  .ant-col-sm-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-sm-push-12 {
    left: 50%;
  }
  .ant-col-sm-pull-12 {
    right: 50%;
  }
  .ant-col-sm-offset-12 {
    margin-left: 50%;
  }
  .ant-col-sm-order-12 {
    order: 12;
  }
  .ant-col-sm-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-sm-push-11 {
    left: 45.83333333%;
  }
  .ant-col-sm-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-sm-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-sm-order-11 {
    order: 11;
  }
  .ant-col-sm-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-sm-push-10 {
    left: 41.66666667%;
  }
  .ant-col-sm-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-sm-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-sm-order-10 {
    order: 10;
  }
  .ant-col-sm-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-sm-push-9 {
    left: 37.5%;
  }
  .ant-col-sm-pull-9 {
    right: 37.5%;
  }
  .ant-col-sm-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-sm-order-9 {
    order: 9;
  }
  .ant-col-sm-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-sm-push-8 {
    left: 33.33333333%;
  }
  .ant-col-sm-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-sm-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-sm-order-8 {
    order: 8;
  }
  .ant-col-sm-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-sm-push-7 {
    left: 29.16666667%;
  }
  .ant-col-sm-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-sm-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-sm-order-7 {
    order: 7;
  }
  .ant-col-sm-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-sm-push-6 {
    left: 25%;
  }
  .ant-col-sm-pull-6 {
    right: 25%;
  }
  .ant-col-sm-offset-6 {
    margin-left: 25%;
  }
  .ant-col-sm-order-6 {
    order: 6;
  }
  .ant-col-sm-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-sm-push-5 {
    left: 20.83333333%;
  }
  .ant-col-sm-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-sm-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-sm-order-5 {
    order: 5;
  }
  .ant-col-sm-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-sm-push-4 {
    left: 16.66666667%;
  }
  .ant-col-sm-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-sm-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-sm-order-4 {
    order: 4;
  }
  .ant-col-sm-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-sm-push-3 {
    left: 12.5%;
  }
  .ant-col-sm-pull-3 {
    right: 12.5%;
  }
  .ant-col-sm-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-sm-order-3 {
    order: 3;
  }
  .ant-col-sm-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-sm-push-2 {
    left: 8.33333333%;
  }
  .ant-col-sm-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-sm-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-sm-order-2 {
    order: 2;
  }
  .ant-col-sm-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-sm-push-1 {
    left: 4.16666667%;
  }
  .ant-col-sm-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-sm-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-sm-order-1 {
    order: 1;
  }
  .ant-col-sm-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-sm-push-0 {
    left: auto;
  }
  .ant-col-sm-pull-0 {
    right: auto;
  }
  .ant-col-sm-offset-0 {
    margin-left: 0;
  }
  .ant-col-sm-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-sm-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-sm-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-sm-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-sm-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-sm-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-sm-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-sm-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-sm-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-sm-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-sm-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-sm-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-sm-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-sm-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-sm-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-sm-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-sm-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-sm-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-sm-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-sm-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-sm-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-sm-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-sm-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-sm-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-sm-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-sm-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-sm-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-sm-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-sm-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-sm-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-sm-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-sm-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-sm-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-sm-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-sm-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-sm-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-sm-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-sm-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-sm-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-sm-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .ant-col-md-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-md-push-24 {
    left: 100%;
  }
  .ant-col-md-pull-24 {
    right: 100%;
  }
  .ant-col-md-offset-24 {
    margin-left: 100%;
  }
  .ant-col-md-order-24 {
    order: 24;
  }
  .ant-col-md-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-md-push-23 {
    left: 95.83333333%;
  }
  .ant-col-md-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-md-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-md-order-23 {
    order: 23;
  }
  .ant-col-md-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-md-push-22 {
    left: 91.66666667%;
  }
  .ant-col-md-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-md-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-md-order-22 {
    order: 22;
  }
  .ant-col-md-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-md-push-21 {
    left: 87.5%;
  }
  .ant-col-md-pull-21 {
    right: 87.5%;
  }
  .ant-col-md-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-md-order-21 {
    order: 21;
  }
  .ant-col-md-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-md-push-20 {
    left: 83.33333333%;
  }
  .ant-col-md-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-md-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-md-order-20 {
    order: 20;
  }
  .ant-col-md-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-md-push-19 {
    left: 79.16666667%;
  }
  .ant-col-md-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-md-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-md-order-19 {
    order: 19;
  }
  .ant-col-md-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-md-push-18 {
    left: 75%;
  }
  .ant-col-md-pull-18 {
    right: 75%;
  }
  .ant-col-md-offset-18 {
    margin-left: 75%;
  }
  .ant-col-md-order-18 {
    order: 18;
  }
  .ant-col-md-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-md-push-17 {
    left: 70.83333333%;
  }
  .ant-col-md-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-md-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-md-order-17 {
    order: 17;
  }
  .ant-col-md-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-md-push-16 {
    left: 66.66666667%;
  }
  .ant-col-md-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-md-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-md-order-16 {
    order: 16;
  }
  .ant-col-md-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-md-push-15 {
    left: 62.5%;
  }
  .ant-col-md-pull-15 {
    right: 62.5%;
  }
  .ant-col-md-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-md-order-15 {
    order: 15;
  }
  .ant-col-md-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-md-push-14 {
    left: 58.33333333%;
  }
  .ant-col-md-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-md-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-md-order-14 {
    order: 14;
  }
  .ant-col-md-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-md-push-13 {
    left: 54.16666667%;
  }
  .ant-col-md-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-md-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-md-order-13 {
    order: 13;
  }
  .ant-col-md-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-md-push-12 {
    left: 50%;
  }
  .ant-col-md-pull-12 {
    right: 50%;
  }
  .ant-col-md-offset-12 {
    margin-left: 50%;
  }
  .ant-col-md-order-12 {
    order: 12;
  }
  .ant-col-md-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-md-push-11 {
    left: 45.83333333%;
  }
  .ant-col-md-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-md-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-md-order-11 {
    order: 11;
  }
  .ant-col-md-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-md-push-10 {
    left: 41.66666667%;
  }
  .ant-col-md-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-md-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-md-order-10 {
    order: 10;
  }
  .ant-col-md-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-md-push-9 {
    left: 37.5%;
  }
  .ant-col-md-pull-9 {
    right: 37.5%;
  }
  .ant-col-md-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-md-order-9 {
    order: 9;
  }
  .ant-col-md-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-md-push-8 {
    left: 33.33333333%;
  }
  .ant-col-md-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-md-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-md-order-8 {
    order: 8;
  }
  .ant-col-md-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-md-push-7 {
    left: 29.16666667%;
  }
  .ant-col-md-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-md-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-md-order-7 {
    order: 7;
  }
  .ant-col-md-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-md-push-6 {
    left: 25%;
  }
  .ant-col-md-pull-6 {
    right: 25%;
  }
  .ant-col-md-offset-6 {
    margin-left: 25%;
  }
  .ant-col-md-order-6 {
    order: 6;
  }
  .ant-col-md-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-md-push-5 {
    left: 20.83333333%;
  }
  .ant-col-md-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-md-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-md-order-5 {
    order: 5;
  }
  .ant-col-md-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-md-push-4 {
    left: 16.66666667%;
  }
  .ant-col-md-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-md-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-md-order-4 {
    order: 4;
  }
  .ant-col-md-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-md-push-3 {
    left: 12.5%;
  }
  .ant-col-md-pull-3 {
    right: 12.5%;
  }
  .ant-col-md-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-md-order-3 {
    order: 3;
  }
  .ant-col-md-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-md-push-2 {
    left: 8.33333333%;
  }
  .ant-col-md-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-md-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-md-order-2 {
    order: 2;
  }
  .ant-col-md-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-md-push-1 {
    left: 4.16666667%;
  }
  .ant-col-md-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-md-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-md-order-1 {
    order: 1;
  }
  .ant-col-md-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-md-push-0 {
    left: auto;
  }
  .ant-col-md-pull-0 {
    right: auto;
  }
  .ant-col-md-offset-0 {
    margin-left: 0;
  }
  .ant-col-md-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-md-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-md-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-md-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-md-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-md-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-md-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-md-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-md-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-md-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-md-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-md-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-md-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-md-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-md-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-md-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-md-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-md-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-md-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-md-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-md-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-md-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-md-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-md-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-md-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-md-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-md-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-md-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-md-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-md-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-md-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-md-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-md-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-md-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-md-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-md-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-md-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-md-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-md-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-md-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-md-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-md-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-md-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-md-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-md-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-md-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-md-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-md-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-md-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-md-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-md-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-md-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-md-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-md-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-md-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-md-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-md-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-md-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-md-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-md-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .ant-col-lg-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-lg-push-24 {
    left: 100%;
  }
  .ant-col-lg-pull-24 {
    right: 100%;
  }
  .ant-col-lg-offset-24 {
    margin-left: 100%;
  }
  .ant-col-lg-order-24 {
    order: 24;
  }
  .ant-col-lg-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-lg-push-23 {
    left: 95.83333333%;
  }
  .ant-col-lg-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-lg-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-lg-order-23 {
    order: 23;
  }
  .ant-col-lg-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-lg-push-22 {
    left: 91.66666667%;
  }
  .ant-col-lg-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-lg-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-lg-order-22 {
    order: 22;
  }
  .ant-col-lg-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-lg-push-21 {
    left: 87.5%;
  }
  .ant-col-lg-pull-21 {
    right: 87.5%;
  }
  .ant-col-lg-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-lg-order-21 {
    order: 21;
  }
  .ant-col-lg-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-lg-push-20 {
    left: 83.33333333%;
  }
  .ant-col-lg-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-lg-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-lg-order-20 {
    order: 20;
  }
  .ant-col-lg-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-lg-push-19 {
    left: 79.16666667%;
  }
  .ant-col-lg-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-lg-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-lg-order-19 {
    order: 19;
  }
  .ant-col-lg-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-lg-push-18 {
    left: 75%;
  }
  .ant-col-lg-pull-18 {
    right: 75%;
  }
  .ant-col-lg-offset-18 {
    margin-left: 75%;
  }
  .ant-col-lg-order-18 {
    order: 18;
  }
  .ant-col-lg-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-lg-push-17 {
    left: 70.83333333%;
  }
  .ant-col-lg-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-lg-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-lg-order-17 {
    order: 17;
  }
  .ant-col-lg-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-lg-push-16 {
    left: 66.66666667%;
  }
  .ant-col-lg-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-lg-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-lg-order-16 {
    order: 16;
  }
  .ant-col-lg-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-lg-push-15 {
    left: 62.5%;
  }
  .ant-col-lg-pull-15 {
    right: 62.5%;
  }
  .ant-col-lg-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-lg-order-15 {
    order: 15;
  }
  .ant-col-lg-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-lg-push-14 {
    left: 58.33333333%;
  }
  .ant-col-lg-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-lg-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-lg-order-14 {
    order: 14;
  }
  .ant-col-lg-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-lg-push-13 {
    left: 54.16666667%;
  }
  .ant-col-lg-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-lg-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-lg-order-13 {
    order: 13;
  }
  .ant-col-lg-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-lg-push-12 {
    left: 50%;
  }
  .ant-col-lg-pull-12 {
    right: 50%;
  }
  .ant-col-lg-offset-12 {
    margin-left: 50%;
  }
  .ant-col-lg-order-12 {
    order: 12;
  }
  .ant-col-lg-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-lg-push-11 {
    left: 45.83333333%;
  }
  .ant-col-lg-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-lg-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-lg-order-11 {
    order: 11;
  }
  .ant-col-lg-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-lg-push-10 {
    left: 41.66666667%;
  }
  .ant-col-lg-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-lg-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-lg-order-10 {
    order: 10;
  }
  .ant-col-lg-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-lg-push-9 {
    left: 37.5%;
  }
  .ant-col-lg-pull-9 {
    right: 37.5%;
  }
  .ant-col-lg-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-lg-order-9 {
    order: 9;
  }
  .ant-col-lg-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-lg-push-8 {
    left: 33.33333333%;
  }
  .ant-col-lg-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-lg-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-lg-order-8 {
    order: 8;
  }
  .ant-col-lg-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-lg-push-7 {
    left: 29.16666667%;
  }
  .ant-col-lg-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-lg-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-lg-order-7 {
    order: 7;
  }
  .ant-col-lg-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-lg-push-6 {
    left: 25%;
  }
  .ant-col-lg-pull-6 {
    right: 25%;
  }
  .ant-col-lg-offset-6 {
    margin-left: 25%;
  }
  .ant-col-lg-order-6 {
    order: 6;
  }
  .ant-col-lg-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-lg-push-5 {
    left: 20.83333333%;
  }
  .ant-col-lg-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-lg-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-lg-order-5 {
    order: 5;
  }
  .ant-col-lg-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-lg-push-4 {
    left: 16.66666667%;
  }
  .ant-col-lg-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-lg-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-lg-order-4 {
    order: 4;
  }
  .ant-col-lg-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-lg-push-3 {
    left: 12.5%;
  }
  .ant-col-lg-pull-3 {
    right: 12.5%;
  }
  .ant-col-lg-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-lg-order-3 {
    order: 3;
  }
  .ant-col-lg-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-lg-push-2 {
    left: 8.33333333%;
  }
  .ant-col-lg-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-lg-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-lg-order-2 {
    order: 2;
  }
  .ant-col-lg-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-lg-push-1 {
    left: 4.16666667%;
  }
  .ant-col-lg-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-lg-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-lg-order-1 {
    order: 1;
  }
  .ant-col-lg-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-lg-push-0 {
    left: auto;
  }
  .ant-col-lg-pull-0 {
    right: auto;
  }
  .ant-col-lg-offset-0 {
    margin-left: 0;
  }
  .ant-col-lg-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-lg-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-lg-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-lg-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-lg-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-lg-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-lg-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-lg-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-lg-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-lg-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-lg-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-lg-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-lg-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-lg-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-lg-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-lg-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-lg-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-lg-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-lg-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-lg-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-lg-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-lg-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-lg-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-lg-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-lg-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-lg-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-lg-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-lg-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-lg-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-lg-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-lg-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-lg-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-lg-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-lg-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-lg-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-lg-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-lg-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-lg-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-lg-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-lg-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .ant-col-xl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xl-push-24 {
    left: 100%;
  }
  .ant-col-xl-pull-24 {
    right: 100%;
  }
  .ant-col-xl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xl-order-24 {
    order: 24;
  }
  .ant-col-xl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-xl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xl-order-23 {
    order: 23;
  }
  .ant-col-xl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-xl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xl-order-22 {
    order: 22;
  }
  .ant-col-xl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-xl-push-21 {
    left: 87.5%;
  }
  .ant-col-xl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xl-order-21 {
    order: 21;
  }
  .ant-col-xl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-xl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xl-order-20 {
    order: 20;
  }
  .ant-col-xl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-xl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xl-order-19 {
    order: 19;
  }
  .ant-col-xl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-xl-push-18 {
    left: 75%;
  }
  .ant-col-xl-pull-18 {
    right: 75%;
  }
  .ant-col-xl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xl-order-18 {
    order: 18;
  }
  .ant-col-xl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-xl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xl-order-17 {
    order: 17;
  }
  .ant-col-xl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-xl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xl-order-16 {
    order: 16;
  }
  .ant-col-xl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-xl-push-15 {
    left: 62.5%;
  }
  .ant-col-xl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xl-order-15 {
    order: 15;
  }
  .ant-col-xl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-xl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xl-order-14 {
    order: 14;
  }
  .ant-col-xl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-xl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xl-order-13 {
    order: 13;
  }
  .ant-col-xl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-xl-push-12 {
    left: 50%;
  }
  .ant-col-xl-pull-12 {
    right: 50%;
  }
  .ant-col-xl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xl-order-12 {
    order: 12;
  }
  .ant-col-xl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-xl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xl-order-11 {
    order: 11;
  }
  .ant-col-xl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-xl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xl-order-10 {
    order: 10;
  }
  .ant-col-xl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-xl-push-9 {
    left: 37.5%;
  }
  .ant-col-xl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xl-order-9 {
    order: 9;
  }
  .ant-col-xl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-xl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xl-order-8 {
    order: 8;
  }
  .ant-col-xl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-xl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xl-order-7 {
    order: 7;
  }
  .ant-col-xl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-xl-push-6 {
    left: 25%;
  }
  .ant-col-xl-pull-6 {
    right: 25%;
  }
  .ant-col-xl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xl-order-6 {
    order: 6;
  }
  .ant-col-xl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-xl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xl-order-5 {
    order: 5;
  }
  .ant-col-xl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-xl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xl-order-4 {
    order: 4;
  }
  .ant-col-xl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-xl-push-3 {
    left: 12.5%;
  }
  .ant-col-xl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xl-order-3 {
    order: 3;
  }
  .ant-col-xl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-xl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xl-order-2 {
    order: 2;
  }
  .ant-col-xl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-xl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xl-order-1 {
    order: 1;
  }
  .ant-col-xl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xl-push-0 {
    left: auto;
  }
  .ant-col-xl-pull-0 {
    right: auto;
  }
  .ant-col-xl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xl-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xl-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-xl-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xl-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-xl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-xl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-xl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-xl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-xl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-xl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-xl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-xl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-xl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-xl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-xl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-xl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-xl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-xl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-xl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-xl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-xl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-xl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-xl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-xl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-xl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-xl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-xl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-xl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-xl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-xl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-xl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-xl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-xl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-xl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-xl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-xl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-xl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-xl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-xl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-xl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 1600px) {
  .ant-col-xxl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xxl-push-24 {
    left: 100%;
  }
  .ant-col-xxl-pull-24 {
    right: 100%;
  }
  .ant-col-xxl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xxl-order-24 {
    order: 24;
  }
  .ant-col-xxl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-xxl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xxl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xxl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xxl-order-23 {
    order: 23;
  }
  .ant-col-xxl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-xxl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xxl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xxl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xxl-order-22 {
    order: 22;
  }
  .ant-col-xxl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-xxl-push-21 {
    left: 87.5%;
  }
  .ant-col-xxl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xxl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xxl-order-21 {
    order: 21;
  }
  .ant-col-xxl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-xxl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xxl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xxl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xxl-order-20 {
    order: 20;
  }
  .ant-col-xxl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-xxl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xxl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xxl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xxl-order-19 {
    order: 19;
  }
  .ant-col-xxl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-xxl-push-18 {
    left: 75%;
  }
  .ant-col-xxl-pull-18 {
    right: 75%;
  }
  .ant-col-xxl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xxl-order-18 {
    order: 18;
  }
  .ant-col-xxl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-xxl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xxl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xxl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xxl-order-17 {
    order: 17;
  }
  .ant-col-xxl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-xxl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xxl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xxl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xxl-order-16 {
    order: 16;
  }
  .ant-col-xxl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-xxl-push-15 {
    left: 62.5%;
  }
  .ant-col-xxl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xxl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xxl-order-15 {
    order: 15;
  }
  .ant-col-xxl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-xxl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xxl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xxl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xxl-order-14 {
    order: 14;
  }
  .ant-col-xxl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-xxl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xxl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xxl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xxl-order-13 {
    order: 13;
  }
  .ant-col-xxl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-xxl-push-12 {
    left: 50%;
  }
  .ant-col-xxl-pull-12 {
    right: 50%;
  }
  .ant-col-xxl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xxl-order-12 {
    order: 12;
  }
  .ant-col-xxl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-xxl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xxl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xxl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xxl-order-11 {
    order: 11;
  }
  .ant-col-xxl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-xxl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xxl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xxl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xxl-order-10 {
    order: 10;
  }
  .ant-col-xxl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-xxl-push-9 {
    left: 37.5%;
  }
  .ant-col-xxl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xxl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xxl-order-9 {
    order: 9;
  }
  .ant-col-xxl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-xxl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xxl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xxl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xxl-order-8 {
    order: 8;
  }
  .ant-col-xxl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-xxl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xxl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xxl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xxl-order-7 {
    order: 7;
  }
  .ant-col-xxl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-xxl-push-6 {
    left: 25%;
  }
  .ant-col-xxl-pull-6 {
    right: 25%;
  }
  .ant-col-xxl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xxl-order-6 {
    order: 6;
  }
  .ant-col-xxl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-xxl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xxl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xxl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xxl-order-5 {
    order: 5;
  }
  .ant-col-xxl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-xxl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xxl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xxl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xxl-order-4 {
    order: 4;
  }
  .ant-col-xxl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-xxl-push-3 {
    left: 12.5%;
  }
  .ant-col-xxl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xxl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xxl-order-3 {
    order: 3;
  }
  .ant-col-xxl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-xxl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xxl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xxl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xxl-order-2 {
    order: 2;
  }
  .ant-col-xxl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-xxl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xxl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xxl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xxl-order-1 {
    order: 1;
  }
  .ant-col-xxl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xxl-push-0 {
    left: auto;
  }
  .ant-col-xxl-pull-0 {
    right: auto;
  }
  .ant-col-xxl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xxl-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xxl-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-xxl-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xxl-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-xxl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-xxl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-xxl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-xxl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-xxl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-xxl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-xxl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-xxl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-xxl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-xxl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-xxl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-xxl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-xxl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-xxl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-xxl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-xxl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-xxl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-xxl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-xxl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-xxl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-xxl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-xxl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-xxl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-xxl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-xxl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-xxl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-xxl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-xxl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-xxl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-xxl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-xxl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-xxl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-xxl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-xxl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-xxl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-xxl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
.ant-row-rtl {
  direction: rtl;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-skeleton {
  display: table;
  width: 100%;
}
.ant-skeleton-header {
  display: table-cell;
  padding-right: 16px;
  vertical-align: top;
}
.ant-skeleton-header .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-content {
  display: table-cell;
  width: 100%;
  vertical-align: top;
}
.ant-skeleton-content .ant-skeleton-title {
  width: 100%;
  height: 16px;
  margin-top: 16px;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 4px;
}
.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 24px;
}
.ant-skeleton-content .ant-skeleton-paragraph {
  padding: 0;
}
.ant-skeleton-content .ant-skeleton-paragraph > li {
  width: 100%;
  height: 16px;
  list-style: none;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 4px;
}
.ant-skeleton-content .ant-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 61%;
}
.ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 16px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
  margin-top: 12px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 28px;
}
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-paragraph > li {
  border-radius: 100px;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
          animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
          animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-button {
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
          animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-input {
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
          animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-image {
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
          animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton.ant-skeleton-block {
  width: 100%;
}
.ant-skeleton.ant-skeleton-block .ant-skeleton-button {
  width: 100%;
}
.ant-skeleton.ant-skeleton-block .ant-skeleton-input {
  width: 100%;
}
.ant-skeleton-element {
  display: inline-block;
  width: auto;
}
.ant-skeleton-element .ant-skeleton-button {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 2px;
  width: 64px;
  min-width: 64px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-circle {
  width: 32px;
  min-width: 32px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-round {
  border-radius: 32px;
}
.ant-skeleton-element .ant-skeleton-button-lg {
  width: 80px;
  min-width: 80px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-circle {
  width: 40px;
  min-width: 40px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-round {
  border-radius: 40px;
}
.ant-skeleton-element .ant-skeleton-button-sm {
  width: 48px;
  min-width: 48px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-circle {
  width: 24px;
  min-width: 24px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-round {
  border-radius: 24px;
}
.ant-skeleton-element .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-input {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 160px;
  min-width: 160px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-input-lg {
  width: 200px;
  min-width: 200px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-input-sm {
  width: 120px;
  min-width: 120px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-image {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 96px;
  height: 96px;
  line-height: 96px;
}
.ant-skeleton-element .ant-skeleton-image.ant-skeleton-image-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-image-path {
  fill: #bfbfbf;
}
.ant-skeleton-element .ant-skeleton-image-svg {
  width: 48px;
  height: 48px;
  line-height: 48px;
  max-width: 192px;
  max-height: 192px;
}
.ant-skeleton-element .ant-skeleton-image-svg.ant-skeleton-image-circle {
  border-radius: 50%;
}
@-webkit-keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
.ant-skeleton-rtl {
  direction: rtl;
}
.ant-skeleton-rtl .ant-skeleton-header {
  padding-right: 0;
  padding-left: 16px;
}
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  -webkit-animation-name: ant-skeleton-loading-rtl;
          animation-name: ant-skeleton-loading-rtl;
}
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  -webkit-animation-name: ant-skeleton-loading-rtl;
          animation-name: ant-skeleton-loading-rtl;
}
@-webkit-keyframes ant-skeleton-loading-rtl {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
@keyframes ant-skeleton-loading-rtl {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-spin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  display: none;
  color: #030047;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1;
}
.ant-spin-nested-loading {
  position: relative;
}
.ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  text-shadow: 0 1px 2px #fff;
}
.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
  margin-top: -20px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -7px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
  padding-top: 2px;
}
.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
  margin-top: -17px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: -16px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  padding-top: 11px;
}
.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -26px;
}
.ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
}
.ant-spin-container::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none \9;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  transition: all 0.3s;
  content: '';
  pointer-events: none;
}
.ant-spin-blur {
  clear: both;
  opacity: 0.5;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}
.ant-spin-blur::after {
  opacity: 0.4;
  pointer-events: auto;
}
.ant-spin-tip {
  color: rgba(0, 0, 0, 0.45);
}
.ant-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  height: 1em;
}
.ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #030047;
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  opacity: 0.3;
  -webkit-animation: antSpinMove 1s infinite linear alternate;
          animation: antSpinMove 1s infinite linear alternate;
}
.ant-spin-dot-item:nth-child(1) {
  top: 0;
  left: 0;
}
.ant-spin-dot-item:nth-child(2) {
  top: 0;
  right: 0;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.ant-spin-dot-item:nth-child(3) {
  right: 0;
  bottom: 0;
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
.ant-spin-dot-item:nth-child(4) {
  bottom: 0;
  left: 0;
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}
.ant-spin-dot-spin {
  transform: rotate(45deg);
  -webkit-animation: antRotate 1.2s infinite linear;
          animation: antRotate 1.2s infinite linear;
}
.ant-spin-sm .ant-spin-dot {
  font-size: 14px;
}
.ant-spin-sm .ant-spin-dot i {
  width: 6px;
  height: 6px;
}
.ant-spin-lg .ant-spin-dot {
  font-size: 32px;
}
.ant-spin-lg .ant-spin-dot i {
  width: 14px;
  height: 14px;
}
.ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .ant-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}
@-webkit-keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@-webkit-keyframes antRotate {
  to {
    transform: rotate(405deg);
  }
}
@keyframes antRotate {
  to {
    transform: rotate(405deg);
  }
}
.ant-spin-rtl {
  direction: rtl;
}
.ant-spin-rtl .ant-spin-dot-spin {
  transform: rotate(-45deg);
  -webkit-animation-name: antRotateRtl;
          animation-name: antRotateRtl;
}
@-webkit-keyframes antRotateRtl {
  to {
    transform: rotate(-405deg);
  }
}
@keyframes antRotateRtl {
  to {
    transform: rotate(-405deg);
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  /* stylelint-disable-next-line selector-no-vendor-prefix */
  display: inline-flex;
}
.ant-input-affix-wrapper::-moz-placeholder {
  opacity: 1;
}
.ant-input-affix-wrapper:-ms-input-placeholder {
  color: #bfbfbf;
  -ms-user-select: none;
      user-select: none;
}
.ant-input-affix-wrapper::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-input-affix-wrapper:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-affix-wrapper:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-input-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-affix-wrapper:hover {
  border-color: #130d54;
  border-right-width: 1px;
}
.ant-input-rtl .ant-input-affix-wrapper:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #130d54;
  box-shadow: 0 0 0 2px rgba(3, 0, 71, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-input-affix-wrapper:focus,
.ant-input-rtl .ant-input-affix-wrapper-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-affix-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-affix-wrapper-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-affix-wrapper[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-affix-wrapper[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-affix-wrapper-borderless,
.ant-input-affix-wrapper-borderless:hover,
.ant-input-affix-wrapper-borderless:focus,
.ant-input-affix-wrapper-borderless-focused,
.ant-input-affix-wrapper-borderless-disabled,
.ant-input-affix-wrapper-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input-affix-wrapper {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-affix-wrapper-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-affix-wrapper-lg input {
  font-size: 16px;
}
.ant-input-affix-wrapper-sm {
  padding: 0px 7px;
}
.ant-input-affix-wrapper-rtl {
  direction: rtl;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #130d54;
  border-right-width: 1px;
  z-index: 1;
}
.ant-input-rtl .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-search-with-button .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  z-index: 0;
}
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  z-index: 1;
}
.ant-input-affix-wrapper-disabled .ant-input[disabled] {
  background: transparent;
}
.ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
}
.ant-input-affix-wrapper > input.ant-input:focus {
  box-shadow: none !important;
}
.ant-input-affix-wrapper::before {
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.ant-input-prefix,
.ant-input-suffix {
  display: flex;
  flex: none;
  align-items: center;
}
.ant-input-prefix > *:not(:last-child),
.ant-input-suffix > *:not(:last-child) {
  margin-right: 8px;
}
.ant-input-show-count-suffix {
  color: rgba(0, 0, 0, 0.45);
}
.ant-input-show-count-has-suffix {
  margin-right: 2px;
}
.ant-input-prefix {
  margin-right: 4px;
}
.ant-input-suffix {
  margin-left: 4px;
}
.ant-input-clear-icon {
  margin: 0;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  vertical-align: -1px;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-input-clear-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-input-clear-icon:active {
  color: rgba(0, 0, 0, 0.85);
}
.ant-input-clear-icon-hidden {
  visibility: hidden;
}
.ant-input-clear-icon-has-suffix {
  margin: 0 4px;
}
.ant-input-affix-wrapper-textarea-with-clear-btn {
  padding: 0 !important;
  border: 0 !important;
}
.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error .ant-input-feedback-icon {
  color: #ff4d4f;
}
.ant-input-status-error .ant-input-prefix {
  color: #ff4d4f;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-error .ant-input-feedback-icon {
  color: #ff4d4f;
}
.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #ff4d4f;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-affix-wrapper-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-textarea-status-validating .ant-input-feedback-icon,
.ant-input-affix-wrapper-status-validating .ant-input-feedback-icon {
  display: inline-block;
  color: #030047;
}
.ant-input-textarea-status-success .ant-input-feedback-icon,
.ant-input-affix-wrapper-status-success .ant-input-feedback-icon {
  color: #52c41a;
  -webkit-animation-name: diffZoomIn1 !important;
          animation-name: diffZoomIn1 !important;
}
.ant-input-textarea-status-error .ant-input-feedback-icon {
  color: #ff4d4f;
}
.ant-input-textarea-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-textarea .ant-input-feedback-icon {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  margin: auto;
}
.ant-input-textarea-status-error.ant-input-textarea-has-feedback .ant-input,
.ant-input-textarea-status-warning.ant-input-textarea-has-feedback .ant-input,
.ant-input-textarea-status-success.ant-input-textarea-has-feedback .ant-input,
.ant-input-textarea-status-validating.ant-input-textarea-has-feedback .ant-input {
  padding-right: 24px;
}
.ant-input-group-wrapper-status-error .ant-input-group-addon {
  color: #ff4d4f;
  border-color: #ff4d4f;
}
.ant-input-group-wrapper-status-warning .ant-input-group-addon {
  color: #faad14;
  border-color: #faad14;
}
.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  /* stylelint-disable-next-line selector-no-vendor-prefix */
}
.ant-input::-moz-placeholder {
  opacity: 1;
}
.ant-input:-ms-input-placeholder {
  color: #bfbfbf;
  -ms-user-select: none;
      user-select: none;
}
.ant-input::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input:hover {
  border-color: #130d54;
  border-right-width: 1px;
}
.ant-input-rtl .ant-input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input:focus,
.ant-input-focused {
  border-color: #130d54;
  box-shadow: 0 0 0 2px rgba(3, 0, 71, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-input:focus,
.ant-input-rtl .ant-input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-borderless,
.ant-input-borderless:hover,
.ant-input-borderless:focus,
.ant-input-borderless-focused,
.ant-input-borderless-disabled,
.ant-input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-lg input {
  font-size: 16px;
}
.ant-input-sm {
  padding: 0px 7px;
}
.ant-input-rtl {
  direction: rtl;
}
.ant-input-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-input-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.ant-input-group > [class*='col-'] {
  padding-right: 8px;
}
.ant-input-group > [class*='col-']:last-child {
  padding-right: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group > .ant-input {
  display: table-cell;
}
.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.ant-input-group-wrap > * {
  display: block !important;
}
.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.ant-input-group .ant-input:focus {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-group .ant-input:hover {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-search-with-button .ant-input-group .ant-input:hover {
  z-index: 0;
}
.ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}
.ant-input-group-addon .ant-select {
  margin: -5px -11px;
}
.ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
.ant-input-group-addon .ant-select-open .ant-select-selector,
.ant-input-group-addon .ant-select-focused .ant-select-selector {
  color: #030047;
}
.ant-input-group-addon .ant-cascader-picker {
  margin: -9px -12px;
  background-color: transparent;
}
.ant-input-group-addon .ant-cascader-picker .ant-cascader-input {
  text-align: left;
  border: 0;
  box-shadow: none;
}
.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input:first-child .ant-select .ant-select-selector,
.ant-input-group-addon:first-child .ant-select .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group-addon:first-child {
  border-right: 0;
}
.ant-input-group-addon:last-child {
  border-left: 0;
}
.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input:last-child .ant-select .ant-select-selector,
.ant-input-group-addon:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group-lg .ant-input,
.ant-input-group-lg > .ant-input-group-addon {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-group-lg .ant-input input,
.ant-input-group-lg > .ant-input-group-addon input {
  font-size: 16px;
}
.ant-input-group-sm .ant-input,
.ant-input-group-sm > .ant-input-group-addon {
  padding: 0px 7px;
}
.ant-input-group-lg .ant-select-single .ant-select-selector {
  height: 40px;
}
.ant-input-group-sm .ant-select-single .ant-select-selector {
  height: 24px;
}
.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-input-group .ant-input-affix-wrapper:not(:first-child),
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group.ant-input-group-compact {
  display: block;
}
.ant-input-group.ant-input-group-compact::before {
  display: table;
  content: '';
}
.ant-input-group.ant-input-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-input-affix-wrapper {
  display: inline-flex;
}
.ant-input-group.ant-input-group-compact > .ant-picker-range {
  display: inline-flex;
}
.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact .ant-input {
  float: none;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
  border-right-width: 1px;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:hover,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:focus,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select-focused {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-arrow {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-right-width: 1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper {
  margin-left: -1px;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 2px 0 0 2px;
}
.ant-input-group > .ant-input-rtl:first-child,
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-radius: 0 2px 2px 0;
}
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
}
.ant-input-group-rtl .ant-input-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #d9d9d9;
}
.ant-input-group-rtl.ant-input-group > .ant-input:last-child,
.ant-input-group-rtl.ant-input-group-addon:last-child {
  border-radius: 2px 0 0 2px;
}
.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-radius: 2px 0 0 2px;
}
.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 0 2px 2px 0;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: 0;
  margin-left: -1px;
  border-left-width: 1px;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-radius: 0 2px 2px 0;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-left-width: 1px;
  border-radius: 2px 0 0 2px;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl + .ant-input-group-wrapper-rtl {
  margin-right: -1px;
  margin-left: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 0 2px 2px 0;
}
.ant-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}
.ant-input-password-icon.anticon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  transition: all 0.3s;
}
.ant-input-password-icon.anticon:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-input[type='color'] {
  height: 32px;
}
.ant-input[type='color'].ant-input-lg {
  height: 40px;
}
.ant-input[type='color'].ant-input-sm {
  height: 24px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.ant-input-textarea-show-count > .ant-input {
  height: 100%;
}
.ant-input-textarea-show-count::after {
  float: right;
  color: rgba(0, 0, 0, 0.45);
  white-space: nowrap;
  content: attr(data-count);
  pointer-events: none;
}
.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: #130d54;
}
.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: #130d54;
}
.ant-input-search .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-search .ant-input-lg {
  line-height: 1.5713;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  left: -1px;
  padding: 0;
  border: 0;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0 2px 2px 0;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
  color: rgba(0, 0, 0, 0.45);
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary).ant-btn-loading::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ant-input-search-button {
  height: 32px;
}
.ant-input-search-button:hover,
.ant-input-search-button:focus {
  z-index: 1;
}
.ant-input-search-large .ant-input-search-button {
  height: 40px;
}
.ant-input-search-small .ant-input-search-button {
  height: 24px;
}
.ant-input-group-wrapper-rtl {
  direction: rtl;
}
.ant-input-group-rtl {
  direction: rtl;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
  border: none;
  outline: none;
}
.ant-input-affix-wrapper-rtl .ant-input-prefix {
  margin: 0 0 0 4px;
}
.ant-input-affix-wrapper-rtl .ant-input-suffix {
  margin: 0 4px 0 0;
}
.ant-input-textarea-rtl {
  direction: rtl;
}
.ant-input-textarea-rtl.ant-input-textarea-show-count::after {
  text-align: left;
}
.ant-input-affix-wrapper-rtl .ant-input-clear-icon-has-suffix {
  margin-right: 0;
  margin-left: 4px;
}
.ant-input-affix-wrapper-rtl .ant-input-clear-icon {
  right: auto;
  left: 8px;
}
.ant-input-search-rtl {
  direction: rtl;
}
.ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-right-color: #130d54;
  border-left-color: #d9d9d9;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper:hover,
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper-focused {
  border-right-color: #130d54;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon {
  right: -1px;
  left: auto;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon .ant-input-search-button {
  border-radius: 2px 0 0 2px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-input {
    height: 32px;
  }
  .ant-input-lg {
    height: 40px;
  }
  .ant-input-sm {
    height: 24px;
  }
  .ant-input-affix-wrapper > input.ant-input {
    height: auto;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-table.ant-table-middle {
  font-size: 14px;
}
.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table.ant-table-middle .ant-table-tbody > tr > td,
.ant-table.ant-table-middle tfoot > tr > th,
.ant-table.ant-table-middle tfoot > tr > td {
  padding: 12px 8px;
}
.ant-table.ant-table-middle .ant-table-filter-trigger {
  margin-right: -4px;
}
.ant-table.ant-table-middle .ant-table-expanded-row-fixed {
  margin: -12px -8px;
}
.ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -12px -8px -12px 25px;
}
.ant-table.ant-table-small {
  font-size: 14px;
}
.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  padding: 8px 8px;
}
.ant-table.ant-table-small .ant-table-filter-trigger {
  margin-right: -4px;
}
.ant-table.ant-table-small .ant-table-expanded-row-fixed {
  margin: -8px -8px;
}
.ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -8px -8px -8px 25px;
}
.ant-table-small .ant-table-thead > tr > th {
  background-color: #fff;
}
.ant-table-small .ant-table-selection-column {
  width: 46px;
  min-width: 46px;
}
.ant-table.ant-table-bordered > .ant-table-title {
  border: 1px solid #f0f0f0;
  border-bottom: 0;
}
.ant-table.ant-table-bordered > .ant-table-container {
  border-left: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th::before,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th::before,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th::before,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th::before {
  background-color: transparent !important;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > .ant-table-cell-fix-right-first::after {
  border-right: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -16px -17px;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td > .ant-table-expanded-row-fixed::after {
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 0;
  border-right: 1px solid #f0f0f0;
  content: '';
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
  border-top: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-expanded-row > td,
.ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-placeholder > td {
  border-right: 0;
}
.ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -12px -9px;
}
.ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -8px -9px;
}
.ant-table.ant-table-bordered > .ant-table-footer {
  border: 1px solid #f0f0f0;
  border-top: 0;
}
.ant-table-cell .ant-table-container:first-child {
  border-top: 0;
}
.ant-table-cell-scrollbar {
  box-shadow: 0 1px 0 1px #fff;
}
.ant-table-wrapper {
  clear: both;
  max-width: 100%;
}
.ant-table-wrapper::before {
  display: table;
  content: '';
}
.ant-table-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  font-size: 14px;
  background: #fff;
  border-radius: 2px;
}
.ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 2px 2px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  position: relative;
  padding: 16px 16px;
  overflow-wrap: break-word;
}
.ant-table-cell-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-table-cell-ellipsis.ant-table-cell-fix-left-last,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first {
  overflow: visible;
}
.ant-table-cell-ellipsis.ant-table-cell-fix-left-last .ant-table-cell-content,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first .ant-table-cell-content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-table-cell-ellipsis .ant-table-column-title {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-table-title {
  padding: 16px 16px;
}
.ant-table-footer {
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.85);
  background: #fafafa;
}
.ant-table-thead > tr > th {
  position: relative;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
}
.ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 1.6em;
  background-color: rgba(0, 0, 0, 0.06);
  transform: translateY(-50%);
  transition: background-color 0.3s;
  content: '';
}
.ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table,
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table {
  margin: -16px -16px -16px 33px;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td,
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td {
  border-bottom: 0;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child,
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child,
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child,
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child {
  border-radius: 0;
}
.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #fafafa;
}
.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #f1ebff;
  border-color: rgba(0, 0, 0, 0.03);
}
.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #eae1ff;
}
.ant-table-summary {
  position: relative;
  z-index: 2;
  background: #fff;
}
div.ant-table-summary {
  box-shadow: 0 -1px 0 #f0f0f0;
}
.ant-table-summary > tr > th,
.ant-table-summary > tr > td {
  border-bottom: 1px solid #f0f0f0;
}
.ant-table-pagination.ant-pagination {
  margin: 16px 0;
}
.ant-table-pagination {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
}
.ant-table-pagination > * {
  flex: none;
}
.ant-table-pagination-left {
  justify-content: flex-start;
}
.ant-table-pagination-center {
  justify-content: center;
}
.ant-table-pagination-right {
  justify-content: flex-end;
}
.ant-table-thead th.ant-table-column-has-sorters {
  cursor: pointer;
  transition: all 0.3s;
}
.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-thead th.ant-table-column-has-sorters:hover::before {
  background-color: transparent !important;
}
.ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover,
.ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-right:hover {
  background: #f5f5f5;
}
.ant-table-thead th.ant-table-column-sort {
  background: #f5f5f5;
}
.ant-table-thead th.ant-table-column-sort::before {
  background-color: transparent !important;
}
td.ant-table-column-sort {
  background: #fafafa;
}
.ant-table-column-title {
  position: relative;
  z-index: 1;
  flex: 1;
}
.ant-table-column-sorters {
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: space-between;
}
.ant-table-column-sorters::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
}
.ant-table-column-sorter {
  margin-left: 4px;
  color: #bfbfbf;
  font-size: 0;
  transition: color 0.3s;
}
.ant-table-column-sorter-inner {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
  font-size: 11px;
}
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #030047;
}
.ant-table-column-sorter-up + .ant-table-column-sorter-down {
  margin-top: -0.3em;
}
.ant-table-column-sorters:hover .ant-table-column-sorter {
  color: #a6a6a6;
}
.ant-table-filter-column {
  display: flex;
  justify-content: space-between;
}
.ant-table-filter-trigger {
  position: relative;
  display: flex;
  align-items: center;
  margin: -4px -8px -4px 4px;
  padding: 0 4px;
  color: #bfbfbf;
  font-size: 12px;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-table-filter-trigger:hover {
  color: rgba(0, 0, 0, 0.45);
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-filter-trigger.active {
  color: #030047;
}
.ant-table-filter-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  min-width: 120px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: 264px;
  overflow-x: hidden;
  border: 0;
  box-shadow: none;
}
.ant-table-filter-dropdown .ant-dropdown-menu:empty::after {
  display: block;
  padding: 8px 0;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  text-align: center;
  content: 'Not Found';
}
.ant-table-filter-dropdown-tree {
  padding: 8px 8px 0;
}
.ant-table-filter-dropdown-tree .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-table-filter-dropdown-tree .ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper,
.ant-table-filter-dropdown-tree .ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper:hover {
  background-color: #564e7a;
}
.ant-table-filter-dropdown-search {
  padding: 8px;
  border-bottom: 1px #f0f0f0 solid;
}
.ant-table-filter-dropdown-search-input input {
  min-width: 140px;
}
.ant-table-filter-dropdown-search-input .anticon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-table-filter-dropdown-checkall {
  width: 100%;
  margin-bottom: 4px;
  margin-left: 4px;
}
.ant-table-filter-dropdown-submenu > ul {
  max-height: calc(100vh - 130px);
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-left: 8px;
}
.ant-table-filter-dropdown-btns {
  display: flex;
  justify-content: space-between;
  padding: 7px 8px;
  overflow: hidden;
  background-color: inherit;
  border-top: 1px solid #f0f0f0;
}
.ant-table-selection-col {
  width: 32px;
}
.ant-table-bordered .ant-table-selection-col {
  width: 50px;
}
table tr th.ant-table-selection-column,
table tr td.ant-table-selection-column {
  padding-right: 8px;
  padding-left: 8px;
  text-align: center;
}
table tr th.ant-table-selection-column .ant-radio-wrapper,
table tr td.ant-table-selection-column .ant-radio-wrapper {
  margin-right: 0;
}
table tr th.ant-table-selection-column.ant-table-cell-fix-left {
  z-index: 3;
}
table tr th.ant-table-selection-column::after {
  background-color: transparent !important;
}
.ant-table-selection {
  position: relative;
  display: inline-flex;
  flex-direction: column;
}
.ant-table-selection-extra {
  position: absolute;
  top: 0;
  z-index: 1;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-margin-start: 100%;
          margin-inline-start: 100%;
  -webkit-padding-start: 4px;
          padding-inline-start: 4px;
}
.ant-table-selection-extra .anticon {
  color: #bfbfbf;
  font-size: 10px;
}
.ant-table-selection-extra .anticon:hover {
  color: #a6a6a6;
}
.ant-table-expand-icon-col {
  width: 48px;
}
.ant-table-row-expand-icon-cell {
  text-align: center;
}
.ant-table-row-indent {
  float: left;
  height: 1px;
}
.ant-table-row-expand-icon {
  color: #1890ff;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  display: inline-flex;
  float: left;
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  padding: 0;
  color: inherit;
  line-height: 17px;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  outline: none;
  transform: scale(0.94117647);
  transition: all 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: #40a9ff;
}
.ant-table-row-expand-icon:active {
  color: #096dd9;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover,
.ant-table-row-expand-icon:active {
  border-color: currentcolor;
}
.ant-table-row-expand-icon::before,
.ant-table-row-expand-icon::after {
  position: absolute;
  background: currentcolor;
  transition: transform 0.3s ease-out;
  content: '';
}
.ant-table-row-expand-icon::before {
  top: 7px;
  right: 3px;
  left: 3px;
  height: 1px;
}
.ant-table-row-expand-icon::after {
  top: 3px;
  bottom: 3px;
  left: 7px;
  width: 1px;
  transform: rotate(90deg);
}
.ant-table-row-expand-icon-collapsed::before {
  transform: rotate(-180deg);
}
.ant-table-row-expand-icon-collapsed::after {
  transform: rotate(0deg);
}
.ant-table-row-expand-icon-spaced {
  background: transparent;
  border: 0;
  visibility: hidden;
}
.ant-table-row-expand-icon-spaced::before,
.ant-table-row-expand-icon-spaced::after {
  display: none;
  content: none;
}
.ant-table-row-indent + .ant-table-row-expand-icon {
  margin-top: 2.5005px;
  margin-right: 8px;
}
tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
  background: #fbfbfb;
}
tr.ant-table-expanded-row .ant-descriptions-view {
  display: flex;
}
tr.ant-table-expanded-row .ant-descriptions-view table {
  flex: auto;
  width: auto;
}
.ant-table .ant-table-expanded-row-fixed {
  position: relative;
  margin: -16px -16px;
  padding: 16px 16px;
}
.ant-table-tbody > tr.ant-table-placeholder {
  text-align: center;
}
.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  color: rgba(0, 0, 0, 0.25);
}
.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: #fff;
}
.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 2;
  background: #fff;
}
.ant-table-cell-fix-left-first::after,
.ant-table-cell-fix-left-last::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  width: 30px;
  transform: translateX(100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table-cell-fix-right-first::after,
.ant-table-cell-fix-right-last::after {
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  width: 30px;
  transform: translateX(-100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table .ant-table-container::before,
.ant-table .ant-table-container::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 30px;
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table .ant-table-container::before {
  left: 0;
}
.ant-table .ant-table-container::after {
  right: 0;
}
.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container {
  position: relative;
}
.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-left .ant-table-cell-fix-left-first::after,
.ant-table-ping-left .ant-table-cell-fix-left-last::after {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-left .ant-table-cell-fix-left-last::before {
  background-color: transparent !important;
}
.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container {
  position: relative;
}
.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-right .ant-table-cell-fix-right-first::after,
.ant-table-ping-right .ant-table-cell-fix-right-last::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-sticky-holder {
  position: -webkit-sticky;
  position: sticky;
  z-index: calc(2 + 1);
  background: #fff;
}
.ant-table-sticky-scroll {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: calc(2 + 1);
  display: flex;
  align-items: center;
  background: #ffffff;
  border-top: 1px solid #f0f0f0;
  opacity: 0.6;
}
.ant-table-sticky-scroll:hover {
  transform-origin: center bottom;
}
.ant-table-sticky-scroll-bar {
  height: 8px;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 4px;
}
.ant-table-sticky-scroll-bar:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.ant-table-sticky-scroll-bar-active {
  background-color: rgba(0, 0, 0, 0.8);
}
@media all and (-ms-high-contrast: none) {
  .ant-table-ping-left .ant-table-cell-fix-left-last::after {
    box-shadow: none !important;
  }
  .ant-table-ping-right .ant-table-cell-fix-right-first::after {
    box-shadow: none !important;
  }
}
.ant-table {
  /* title + table */
  /* table */
  /* table + footer */
}
.ant-table-title {
  border-radius: 2px 2px 0 0;
}
.ant-table-title + .ant-table-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ant-table-title + .ant-table-container table > thead > tr:first-child th:first-child {
  border-radius: 0;
}
.ant-table-title + .ant-table-container table > thead > tr:first-child th:last-child {
  border-radius: 0;
}
.ant-table-container {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 2px;
}
.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 2px;
}
.ant-table-footer {
  border-radius: 0 0 2px 2px;
}
.ant-table-wrapper-rtl {
  direction: rtl;
}
.ant-table-rtl {
  direction: rtl;
}
.ant-table-wrapper-rtl .ant-table table {
  text-align: right;
}
.ant-table-wrapper-rtl .ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.ant-table-wrapper-rtl .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  right: auto;
  left: 0;
}
.ant-table-wrapper-rtl .ant-table-thead > tr > th {
  text-align: right;
}
.ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table.ant-table-rtl {
  margin: -16px 33px -16px -16px;
}
.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-left {
  justify-content: flex-end;
}
.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-right {
  justify-content: flex-start;
}
.ant-table-wrapper-rtl .ant-table-column-sorter {
  margin-right: 4px;
  margin-left: 0;
}
.ant-table-wrapper-rtl .ant-table-filter-column-title {
  padding: 16px 16px 16px 2.3em;
}
.ant-table-rtl .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
  padding: 0 0 0 2.3em;
}
.ant-table-wrapper-rtl .ant-table-filter-trigger {
  margin: -4px 4px -4px -8px;
}
.ant-dropdown-rtl .ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-dropdown-rtl .ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span,
.ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-right: 8px;
  padding-left: 0;
}
.ant-table-wrapper-rtl .ant-table-selection {
  text-align: center;
}
.ant-table-wrapper-rtl .ant-table-row-indent {
  float: right;
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon {
  float: right;
}
.ant-table-wrapper-rtl .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon::after {
  transform: rotate(-90deg);
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::before {
  transform: rotate(180deg);
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::after {
  transform: rotate(0deg);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-empty {
  margin: 0 8px;
  font-size: 14px;
  line-height: 1.5715;
  text-align: center;
}
.ant-empty-image {
  height: 100px;
  margin-bottom: 8px;
}
.ant-empty-image img {
  height: 100%;
}
.ant-empty-image svg {
  height: 100%;
  margin: auto;
}
.ant-empty-footer {
  margin-top: 16px;
}
.ant-empty-normal {
  margin: 32px 0;
  color: rgba(0, 0, 0, 0.25);
}
.ant-empty-normal .ant-empty-image {
  height: 40px;
}
.ant-empty-small {
  margin: 8px 0;
  color: rgba(0, 0, 0, 0.25);
}
.ant-empty-small .ant-empty-image {
  height: 35px;
}
.ant-empty-img-default-ellipse {
  fill: #f5f5f5;
  fill-opacity: 0.8;
}
.ant-empty-img-default-path-1 {
  fill: #aeb8c2;
}
.ant-empty-img-default-path-2 {
  fill: url('#linearGradient-1');
}
.ant-empty-img-default-path-3 {
  fill: #f5f5f7;
}
.ant-empty-img-default-path-4 {
  fill: #dce0e6;
}
.ant-empty-img-default-path-5 {
  fill: #dce0e6;
}
.ant-empty-img-default-g {
  fill: #fff;
}
.ant-empty-img-simple-ellipse {
  fill: #f5f5f5;
}
.ant-empty-img-simple-g {
  stroke: #d9d9d9;
}
.ant-empty-img-simple-path {
  fill: #fafafa;
}
.ant-empty-rtl {
  direction: rtl;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  font-size: 0;
}
.ant-radio-group .ant-badge-count {
  z-index: 1;
}
.ant-radio-group > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
  border-left: none;
}
.ant-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-flex;
  align-items: baseline;
  margin-right: 8px;
  cursor: pointer;
}
.ant-radio-wrapper-disabled {
  cursor: not-allowed;
}
.ant-radio-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-radio {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 0.2em;
  display: inline-block;
  outline: none;
  cursor: pointer;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #030047;
}
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px #7d7a87;
}
.ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #030047;
  border-radius: 50%;
  visibility: hidden;
  -webkit-animation: antRadioEffect 0.36s ease-in-out;
          animation: antRadioEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  content: '';
}
.ant-radio:hover::after,
.ant-radio-wrapper:hover .ant-radio::after {
  visibility: visible;
}
.ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  transition: all 0.3s;
}
.ant-radio-inner::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
  background-color: #030047;
  border-top: 0;
  border-left: 0;
  border-radius: 16px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
.ant-radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #030047;
}
.ant-radio-checked .ant-radio-inner::after {
  transform: scale(0.5);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-radio-disabled {
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner::after {
  background-color: rgba(0, 0, 0, 0.2);
}
.ant-radio-disabled .ant-radio-input {
  cursor: not-allowed;
}
.ant-radio-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
span.ant-radio + * {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding: 0 15px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 30px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-left-width: 0;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.ant-radio-button-wrapper a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-radio-button-wrapper > .ant-radio-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.ant-radio-group-large .ant-radio-button-wrapper {
  height: 40px;
  font-size: 16px;
  line-height: 38px;
}
.ant-radio-group-small .ant-radio-button-wrapper {
  height: 24px;
  padding: 0 7px;
  line-height: 22px;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  box-sizing: content-box;
  width: 1px;
  height: 100%;
  padding: 1px 0;
  background-color: #d9d9d9;
  transition: background-color 0.3s;
  content: '';
}
.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0 2px 2px 0;
}
.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 2px;
}
.ant-radio-button-wrapper:hover {
  position: relative;
  color: #030047;
}
.ant-radio-button-wrapper:focus-within {
  box-shadow: 0 0 0 3px #7d7a87;
}
.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type='checkbox'],
.ant-radio-button-wrapper input[type='radio'] {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #030047;
  background: #fff;
  border-color: #030047;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #030047;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #030047;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #130d54;
  border-color: #130d54;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: #130d54;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #010021;
  border-color: #010021;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
  background-color: #010021;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px #7d7a87;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #030047;
  border-color: #030047;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #130d54;
  border-color: #130d54;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #fff;
  background: #010021;
  border-color: #010021;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px #7d7a87;
}
.ant-radio-button-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled:first-child {
  border-left-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: rgba(0, 0, 0, 0.25);
  background-color: #e6e6e6;
  border-color: #d9d9d9;
  box-shadow: none;
}
@-webkit-keyframes antRadioEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antRadioEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.ant-radio-group.ant-radio-group-rtl {
  direction: rtl;
}
.ant-radio-wrapper.ant-radio-wrapper-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl {
  border-right-width: 0;
  border-left-width: 1px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:not(:first-child)::before {
  right: -1px;
  left: 0;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
  border-right: 1px solid #d9d9d9;
  border-radius: 0 2px 2px 0;
}
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #130d54;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:last-child {
  border-radius: 2px 0 0 2px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
  border-right-color: #d9d9d9;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@-webkit-keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.ant-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #6b57f8;
}
.ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #6b57f8;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
          animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  content: '';
}
.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: visible;
}
.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #6b57f8;
  border-color: #6b57f8;
}
.ant-checkbox-disabled {
  cursor: not-allowed;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-checkbox-disabled .ant-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-checkbox-disabled:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox-disabled::after {
  visibility: hidden;
}
.ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.ant-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 8px;
}
.ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.ant-checkbox-group-item {
  margin-right: 8px;
}
.ant-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 0;
}
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #6b57f8;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-checkbox-rtl {
  direction: rtl;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 8px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pagination {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}
.ant-pagination ul,
.ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' ';
}
.ant-pagination-total-text {
  display: inline-block;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  vertical-align: middle;
}
.ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: 'Inter', sans-serif !important;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: rgba(0, 0, 0, 0.85);
  transition: none;
}
.ant-pagination-item a:hover {
  text-decoration: none;
}
.ant-pagination-item:hover {
  border-color: #030047;
  transition: all 0.3s;
}
.ant-pagination-item:hover a {
  color: #030047;
}
.ant-pagination-item:focus-visible {
  border-color: #030047;
  transition: all 0.3s;
}
.ant-pagination-item:focus-visible a {
  color: #030047;
}
.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #030047;
}
.ant-pagination-item-active a {
  color: #030047;
}
.ant-pagination-item-active:hover {
  border-color: #130d54;
}
.ant-pagination-item-active:focus-visible {
  border-color: #130d54;
}
.ant-pagination-item-active:hover a {
  color: #130d54;
}
.ant-pagination-item-active:focus-visible a {
  color: #130d54;
}
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  outline: 0;
}
.ant-pagination-jump-prev .ant-pagination-item-container,
.ant-pagination-jump-next .ant-pagination-item-container {
  position: relative;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #030047;
  font-size: 12px;
  letter-spacing: -1px;
  opacity: 0;
  transition: all 0.2s;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  color: rgba(0, 0, 0, 0.25);
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 2px;
  text-align: center;
  text-indent: 0.13em;
  opacity: 1;
  transition: all 0.2s;
}
.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 1;
}
.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0;
}
.ant-pagination-jump-prev:focus-visible .ant-pagination-item-link-icon,
.ant-pagination-jump-next:focus-visible .ant-pagination-item-link-icon {
  opacity: 1;
}
.ant-pagination-jump-prev:focus-visible .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:focus-visible .ant-pagination-item-ellipsis {
  opacity: 0;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 8px;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-family: 'Inter', sans-serif !important;
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-pagination-prev,
.ant-pagination-next {
  font-family: Arial, Helvetica, sans-serif;
  outline: 0;
}
.ant-pagination-prev button,
.ant-pagination-next button {
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-pagination-prev:hover button,
.ant-pagination-next:hover button {
  border-color: #130d54;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  transition: all 0.3s;
}
.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-next:focus-visible .ant-pagination-item-link {
  color: #030047;
  border-color: #030047;
}
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #030047;
  border-color: #030047;
}
.ant-pagination-disabled,
.ant-pagination-disabled:hover {
  cursor: not-allowed;
}
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-disabled:focus-visible {
  cursor: not-allowed;
}
.ant-pagination-disabled:focus-visible .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-slash {
  margin: 0 10px 0 5px;
}
.ant-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}
@media all and (-ms-high-contrast: none) {
  .ant-pagination-options *::-ms-backdrop,
  .ant-pagination-options {
    vertical-align: top;
  }
}
.ant-pagination-options-size-changer.ant-select {
  display: inline-block;
  width: auto;
}
.ant-pagination-options-quick-jumper {
  display: inline-block;
  height: 32px;
  margin-left: 8px;
  line-height: 32px;
  vertical-align: top;
}
.ant-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  /* stylelint-disable-next-line selector-no-vendor-prefix */
  width: 50px;
  height: 32px;
  margin: 0 8px;
}
.ant-pagination-options-quick-jumper input::-moz-placeholder {
  opacity: 1;
}
.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  color: #bfbfbf;
  -ms-user-select: none;
      user-select: none;
}
.ant-pagination-options-quick-jumper input::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-pagination-options-quick-jumper input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:hover {
  border-color: #130d54;
  border-right-width: 1px;
}
.ant-pagination-options-quick-jumper input:focus,
.ant-pagination-options-quick-jumper input-focused {
  border-color: #130d54;
  box-shadow: 0 0 0 2px rgba(3, 0, 71, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-pagination-options-quick-jumper input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-pagination-options-quick-jumper input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-pagination-options-quick-jumper input-borderless,
.ant-pagination-options-quick-jumper input-borderless:hover,
.ant-pagination-options-quick-jumper input-borderless:focus,
.ant-pagination-options-quick-jumper input-borderless-focused,
.ant-pagination-options-quick-jumper input-borderless-disabled,
.ant-pagination-options-quick-jumper input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-pagination-options-quick-jumper input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-pagination-options-quick-jumper input-lg input {
  font-size: 16px;
}
.ant-pagination-options-quick-jumper input-sm {
  padding: 0px 7px;
}
.ant-pagination-simple .ant-pagination-prev,
.ant-pagination-simple .ant-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
  height: 24px;
  background-color: transparent;
  border: 0;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination-simple .ant-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}
.ant-pagination-simple .ant-pagination-simple-pager input {
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  transition: border-color 0.3s;
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: #030047;
}
.ant-pagination-simple .ant-pagination-simple-pager input:focus {
  border-color: #130d54;
  box-shadow: 0 0 0 2px rgba(3, 0, 71, 0.2);
}
.ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination.mini .ant-pagination-total-text,
.ant-pagination.mini .ant-pagination-simple-pager {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-item {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
}
.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-jump-prev,
.ant-pagination.mini .ant-pagination-jump-next {
  height: 24px;
  margin-right: 0;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options {
  margin-left: 2px;
}
.ant-pagination.mini .ant-pagination-options-size-changer {
  top: 0px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper input {
  padding: 0px 7px;
  width: 44px;
  height: 24px;
}
.ant-pagination.ant-pagination-disabled {
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item a {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border: none;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
  background: #e6e6e6;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
  color: rgba(0, 0, 0, 0.25);
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-simple.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  background: transparent;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link-icon {
  opacity: 0;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-ellipsis {
  opacity: 1;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
  color: rgba(0, 0, 0, 0.25);
}
@media only screen and (max-width: 992px) {
  .ant-pagination-item-after-jump-prev,
  .ant-pagination-item-before-jump-next {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: none;
  }
}
.ant-pagination-rtl .ant-pagination-total-text {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-item,
.ant-pagination-rtl .ant-pagination-prev,
.ant-pagination-rtl .ant-pagination-jump-prev,
.ant-pagination-rtl .ant-pagination-jump-next {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-slash {
  margin: 0 5px 0 10px;
}
.ant-pagination-rtl .ant-pagination-options {
  margin-right: 16px;
  margin-left: 0;
}
.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-size-changer.ant-select {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-quick-jumper {
  margin-left: 0;
}
.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager input {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl.ant-pagination.mini .ant-pagination-options {
  margin-right: 2px;
  margin-left: 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-select-single .ant-select-selector {
  display: flex;
}
.ant-select-single .ant-select-selector .ant-select-selection-search {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  left: 11px;
}
.ant-select-single .ant-select-selector .ant-select-selection-search-input {
  width: 100%;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 30px;
  transition: all 0.3s;
}
@supports (-moz-appearance: meterbar) {
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 30px;
  }
}
.ant-select-single .ant-select-selector .ant-select-selection-item {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  transition: none;
  pointer-events: none;
}
.ant-select-single .ant-select-selector::after,
.ant-select-single .ant-select-selector .ant-select-selection-item::after,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 25px;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
}
.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #bfbfbf;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 32px;
  padding: 0 11px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 30px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
  line-height: 30px;
}
.ant-select-single.ant-select-customize-input .ant-select-selector::after {
  display: none;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
  position: static;
  width: 100%;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
  position: absolute;
  right: 0;
  left: 0;
  padding: 0 11px;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder::after {
  display: none;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 38px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  height: 24px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 22px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 22px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selection-search {
  right: 7px;
  left: 7px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 7px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 28px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 21px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 11px;
}
/**
 * Do not merge `height` & `line-height` under style with `selection` & `search`,
 * since chrome may update to redesign with its align logic.
 */
.ant-select-selection-overflow {
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  max-width: 100%;
}
.ant-select-selection-overflow-item {
  flex: none;
  align-self: center;
  max-width: 100%;
}
.ant-select-multiple .ant-select-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1px 4px;
}
.ant-select-show-search.ant-select-multiple .ant-select-selector {
  cursor: text;
}
.ant-select-disabled.ant-select-multiple .ant-select-selector {
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-select-multiple .ant-select-selector::after {
  display: inline-block;
  width: 0;
  margin: 2px 0;
  line-height: 24px;
  content: '\a0';
}
.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 24px;
}
.ant-select-multiple .ant-select-selection-item {
  position: relative;
  display: flex;
  flex: none;
  box-sizing: border-box;
  max-width: 100%;
  height: 24px;
  margin-top: 2px;
  margin-bottom: 2px;
  line-height: 22px;
  background: #f5f5f5;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  cursor: default;
  transition: font-size 0.3s, line-height 0.3s, height 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-margin-end: 4px;
          margin-inline-end: 4px;
  -webkit-padding-start: 8px;
          padding-inline-start: 8px;
  -webkit-padding-end: 4px;
          padding-inline-end: 4px;
}
.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: #bfbfbf;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-select-multiple .ant-select-selection-item-content {
  display: inline-block;
  margin-right: 4px;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}
.ant-select-multiple .ant-select-selection-item-remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  font-size: 10px;
  line-height: inherit;
  cursor: pointer;
}
.ant-select-multiple .ant-select-selection-item-remove > * {
  line-height: 1;
}
.ant-select-multiple .ant-select-selection-item-remove svg {
  display: inline-block;
}
.ant-select-multiple .ant-select-selection-item-remove::before {
  display: none;
}
.ant-select-multiple .ant-select-selection-item-remove .ant-select-multiple .ant-select-selection-item-remove-icon {
  display: block;
}
.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: -0.2em;
}
.ant-select-multiple .ant-select-selection-item-remove:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-select-multiple .ant-select-selection-overflow-item + .ant-select-selection-overflow-item .ant-select-selection-search {
  -webkit-margin-start: 0;
          margin-inline-start: 0;
}
.ant-select-multiple .ant-select-selection-search {
  position: relative;
  max-width: 100%;
  -webkit-margin-start: 7px;
          margin-inline-start: 7px;
}
.ant-select-multiple .ant-select-selection-search-input,
.ant-select-multiple .ant-select-selection-search-mirror {
  height: 24px;
  font-family: 'Inter', sans-serif !important;
  line-height: 24px;
  transition: all 0.3s;
}
.ant-select-multiple .ant-select-selection-search-input {
  width: 100%;
  min-width: 4.1px;
}
.ant-select-multiple .ant-select-selection-search-mirror {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  white-space: pre;
  visibility: hidden;
}
.ant-select-multiple .ant-select-selection-placeholder {
  position: absolute;
  top: 50%;
  right: 11px;
  left: 11px;
  transform: translateY(-50%);
  transition: all 0.3s;
}
.ant-select-multiple.ant-select-lg .ant-select-selector::after {
  line-height: 32px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 30px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-search {
  height: 32px;
  line-height: 32px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-search-input,
.ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror {
  height: 32px;
  line-height: 30px;
}
.ant-select-multiple.ant-select-sm .ant-select-selector::after {
  line-height: 16px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-item {
  height: 16px;
  line-height: 14px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  height: 16px;
  line-height: 16px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search-input,
.ant-select-multiple.ant-select-sm .ant-select-selection-search-mirror {
  height: 16px;
  line-height: 14px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  left: 7px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  -webkit-margin-start: 3px;
          margin-inline-start: 3px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 32px;
}
.ant-select-disabled .ant-select-selection-item-remove {
  display: none;
}
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border-color: #ff4d4f !important;
}
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-select-status-error .ant-select-feedback-icon {
  color: #ff4d4f;
}
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border-color: #faad14 !important;
}
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-select-status-warning .ant-select-feedback-icon {
  color: #faad14;
}
.ant-select-status-success .ant-select-feedback-icon {
  color: #52c41a;
}
.ant-select-status-validating .ant-select-feedback-icon {
  color: #030047;
}
.ant-select-status-error.ant-select-has-feedback .ant-select-clear,
.ant-select-status-warning.ant-select-has-feedback .ant-select-clear,
.ant-select-status-success.ant-select-has-feedback .ant-select-clear,
.ant-select-status-validating.ant-select-has-feedback .ant-select-clear {
  right: 32px;
}
.ant-select-status-error.ant-select-has-feedback .ant-select-selection-selected-value,
.ant-select-status-warning.ant-select-has-feedback .ant-select-selection-selected-value,
.ant-select-status-success.ant-select-has-feedback .ant-select-selection-selected-value,
.ant-select-status-validating.ant-select-has-feedback .ant-select-selection-selected-value {
  padding-right: 42px;
}
.ant-select-feedback-icon {
  font-size: 14px;
  text-align: center;
  visibility: visible;
  -webkit-animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
          animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none;
}
.ant-select-feedback-icon:not(:first-child) {
  margin-left: 8px;
}
/* Reset search input style */
.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: pointer;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: text;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: auto;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #130d54;
  box-shadow: 0 0 0 2px rgba(3, 0, 71, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #f5f5f5;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: not-allowed;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input::-webkit-search-cancel-button {
  display: none;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #130d54;
  border-right-width: 1px;
}
.ant-select-selection-item {
  flex: 1;
  overflow: hidden;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media all and (-ms-high-contrast: none) {
  .ant-select-selection-item *::-ms-backdrop,
  .ant-select-selection-item {
    flex: auto;
  }
}
.ant-select-selection-placeholder {
  flex: 1;
  overflow: hidden;
  color: #bfbfbf;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
@media all and (-ms-high-contrast: none) {
  .ant-select-selection-placeholder *::-ms-backdrop,
  .ant-select-selection-placeholder {
    flex: auto;
  }
}
.ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  display: flex;
  align-items: center;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
}
.ant-select-arrow > * {
  line-height: 1;
}
.ant-select-arrow svg {
  display: inline-block;
}
.ant-select-arrow::before {
  display: none;
}
.ant-select-arrow .ant-select-arrow-icon {
  display: block;
}
.ant-select-arrow .anticon {
  vertical-align: top;
  transition: transform 0.3s;
}
.ant-select-arrow .anticon > svg {
  vertical-align: top;
}
.ant-select-arrow .anticon:not(.ant-select-suffix) {
  pointer-events: auto;
}
.ant-select-disabled .ant-select-arrow {
  cursor: not-allowed;
}
.ant-select-clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}
.ant-select-clear::before {
  display: block;
}
.ant-select-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-select:hover .ant-select-clear {
  opacity: 1;
}
.ant-select-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  padding: 4px 0;
  overflow: hidden;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-select-dropdown-hidden {
  display: none;
}
.ant-select-dropdown-empty {
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-item-empty {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-item {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-select-item-group {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: default;
}
.ant-select-item-option {
  display: flex;
}
.ant-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-select-item-option-state {
  flex: none;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: transparent;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #030047;
}
.ant-select-item-option-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-item-option-disabled.ant-select-item-option-selected {
  background-color: #f5f5f5;
}
.ant-select-item-option-grouped {
  padding-left: 24px;
}
.ant-select-lg {
  font-size: 16px;
}
.ant-select-borderless .ant-select-selector {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.ant-select-rtl {
  direction: rtl;
}
.ant-select-rtl .ant-select-arrow {
  right: initial;
  left: 11px;
}
.ant-select-rtl .ant-select-clear {
  right: initial;
  left: 11px;
}
.ant-select-dropdown-rtl {
  direction: rtl;
}
.ant-select-dropdown-rtl .ant-select-item-option-grouped {
  padding-right: 24px;
  padding-left: 12px;
}
.ant-select-rtl.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-rtl.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 4px;
  padding-left: 24px;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-item {
  text-align: right;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-item-content {
  margin-right: 0;
  margin-left: 4px;
  text-align: right;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-search-mirror {
  right: 0;
  left: auto;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-placeholder {
  right: 11px;
  left: auto;
}
.ant-select-rtl.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  right: 7px;
}
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  right: 0;
  left: 9px;
  text-align: right;
}
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 11px;
  left: 25px;
}
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 18px;
}
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 6px;
}
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 21px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@-webkit-keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
@-webkit-keyframes ant-tree-node-fx-do-not-use {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes ant-tree-node-fx-do-not-use {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ant-tree.ant-tree-directory .ant-tree-treenode {
  position: relative;
}
.ant-tree.ant-tree-directory .ant-tree-treenode::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  transition: background-color 0.3s;
  content: '';
  pointer-events: none;
}
.ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
  background: #f5f5f5;
}
.ant-tree.ant-tree-directory .ant-tree-treenode > * {
  z-index: 1;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-switcher {
  transition: color 0.3s;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper {
  border-radius: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
  background: transparent;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
  background: #030047;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
  color: #fff;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper {
  color: #fff;
  background: transparent;
}
.ant-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: #6b57f8;
}
.ant-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #6b57f8;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
          animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  content: '';
}
.ant-tree-checkbox:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox::after {
  visibility: visible;
}
.ant-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #6b57f8;
  border-color: #6b57f8;
}
.ant-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.ant-tree-checkbox-group-item {
  margin-right: 8px;
}
.ant-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #6b57f8;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-tree {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  background: #fff;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
  background: #7d7a87;
}
.ant-tree-list-holder-inner {
  align-items: flex-start;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner {
  align-items: stretch;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
  flex: auto;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging {
  position: relative;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  border: 1px solid #030047;
  opacity: 0;
  -webkit-animation: ant-tree-node-fx-do-not-use 0.3s;
          animation: ant-tree-node-fx-do-not-use 0.3s;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
.ant-tree .ant-tree-treenode {
  display: flex;
  align-items: flex-start;
  padding: 0 0 4px 0;
  outline: none;
}
.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree .ant-tree-treenode-active .ant-tree-node-content-wrapper {
  background: #f5f5f5;
}
.ant-tree .ant-tree-treenode:not(.ant-tree .ant-tree-treenode-disabled).filter-node .ant-tree-title {
  color: inherit;
  font-weight: 500;
}
.ant-tree-indent {
  align-self: stretch;
  white-space: nowrap;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-tree-indent-unit {
  display: inline-block;
  width: 24px;
}
.ant-tree-draggable-icon {
  width: 24px;
  line-height: 24px;
  text-align: center;
  opacity: 0.2;
  transition: opacity 0.3s;
}
.ant-tree-treenode:hover .ant-tree-draggable-icon {
  opacity: 0.45;
}
.ant-tree-switcher {
  position: relative;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-tree-switcher .ant-tree-switcher-icon,
.ant-tree-switcher .ant-select-tree-switcher-icon {
  display: inline-block;
  font-size: 10px;
  vertical-align: baseline;
}
.ant-tree-switcher .ant-tree-switcher-icon svg,
.ant-tree-switcher .ant-select-tree-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-tree-switcher-noop {
  cursor: default;
}
.ant-tree-switcher_close .ant-tree-switcher-icon svg {
  transform: rotate(-90deg);
}
.ant-tree-switcher-loading-icon {
  color: #030047;
}
.ant-tree-switcher-leaf-line {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.ant-tree-switcher-leaf-line::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  margin-left: -1px;
  border-right: 1px solid #d9d9d9;
  content: ' ';
}
.ant-tree-switcher-leaf-line::after {
  position: absolute;
  width: 10px;
  height: 14px;
  border-bottom: 1px solid #d9d9d9;
  content: ' ';
}
.ant-tree-checkbox {
  top: initial;
  margin: 4px 8px 0 0;
}
.ant-tree .ant-tree-node-content-wrapper {
  position: relative;
  z-index: auto;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
}
.ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #564e7a;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle:empty {
  display: none;
}
.ant-tree-unselectable .ant-tree-node-content-wrapper:hover {
  background-color: transparent;
}
.ant-tree-node-content-wrapper {
  line-height: 24px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-tree-node-content-wrapper .ant-tree-drop-indicator {
  position: absolute;
  z-index: 1;
  height: 2px;
  background-color: #030047;
  border-radius: 1px;
  pointer-events: none;
}
.ant-tree-node-content-wrapper .ant-tree-drop-indicator::after {
  position: absolute;
  top: -3px;
  left: -6px;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 2px solid #030047;
  border-radius: 50%;
  content: '';
}
.ant-tree .ant-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px #030047;
}
.ant-tree-show-line .ant-tree-indent-unit {
  position: relative;
  height: 100%;
}
.ant-tree-show-line .ant-tree-indent-unit::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  border-right: 1px solid #d9d9d9;
  content: '';
}
.ant-tree-show-line .ant-tree-indent-unit-end::before {
  display: none;
}
.ant-tree-show-line .ant-tree-switcher {
  background: #fff;
}
.ant-tree-show-line .ant-tree-switcher-line-icon {
  vertical-align: -0.15em;
}
.ant-tree .ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line::before {
  top: auto !important;
  bottom: auto !important;
  height: 14px !important;
}
.ant-tree-rtl {
  direction: rtl;
}
.ant-tree-rtl .ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator::after {
  right: -6px;
  left: unset;
}
.ant-tree .ant-tree-treenode-rtl {
  direction: rtl;
}
.ant-tree-rtl .ant-tree-switcher_close .ant-tree-switcher-icon svg {
  transform: rotate(90deg);
}
.ant-tree-rtl.ant-tree-show-line .ant-tree-indent-unit::before {
  right: auto;
  left: -13px;
  border-right: none;
  border-left: 1px solid #d9d9d9;
}
.ant-tree-rtl.ant-tree-checkbox {
  margin: 4px 0 0 8px;
}
.ant-tree-select-dropdown-rtl .ant-select-tree-checkbox {
  margin: 4px 0 0 8px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: fixed;
  top: 8px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}
.ant-message-notice {
  padding: 8px;
  text-align: center;
}
.ant-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: all;
}
.ant-message-success .anticon {
  color: #52c41a;
}
.ant-message-error .anticon {
  color: #ff4d4f;
}
.ant-message-warning .anticon {
  color: #faad14;
}
.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: #030047;
}
.ant-message .anticon {
  position: relative;
  top: 1px;
  margin-right: 8px;
  font-size: 16px;
}
.ant-message-notice.ant-move-up-leave.ant-move-up-leave-active {
  -webkit-animation-name: MessageMoveOut;
          animation-name: MessageMoveOut;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
}
@-webkit-keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
.ant-message-rtl {
  direction: rtl;
}
.ant-message-rtl span {
  direction: rtl;
}
.ant-message-rtl .anticon {
  margin-right: 0;
  margin-left: 8px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-input-number-affix-wrapper {
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  /* stylelint-disable-next-line selector-no-vendor-prefix */
  position: relative;
  display: inline-flex;
  width: 90px;
  padding: 0;
  -webkit-padding-start: 11px;
          padding-inline-start: 11px;
}
.ant-input-number-affix-wrapper::-moz-placeholder {
  opacity: 1;
}
.ant-input-number-affix-wrapper:-ms-input-placeholder {
  color: #bfbfbf;
  -ms-user-select: none;
      user-select: none;
}
.ant-input-number-affix-wrapper::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-input-number-affix-wrapper:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number-affix-wrapper:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-input-number-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number-affix-wrapper:hover {
  border-color: #130d54;
  border-right-width: 1px;
}
.ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-focused {
  border-color: #130d54;
  box-shadow: 0 0 0 2px rgba(3, 0, 71, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-affix-wrapper-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number-affix-wrapper[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-affix-wrapper[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number-affix-wrapper-borderless,
.ant-input-number-affix-wrapper-borderless:hover,
.ant-input-number-affix-wrapper-borderless:focus,
.ant-input-number-affix-wrapper-borderless-focused,
.ant-input-number-affix-wrapper-borderless-disabled,
.ant-input-number-affix-wrapper-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input-number-affix-wrapper {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-number-affix-wrapper-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-number-affix-wrapper-lg input {
  font-size: 16px;
}
.ant-input-number-affix-wrapper-sm {
  padding: 0px 7px;
}
.ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled):hover {
  border-color: #130d54;
  border-right-width: 1px;
  z-index: 1;
}
.ant-input-number-affix-wrapper-focused,
.ant-input-number-affix-wrapper:focus {
  z-index: 1;
}
.ant-input-number-affix-wrapper-disabled .ant-input-number[disabled] {
  background: transparent;
}
.ant-input-number-affix-wrapper > div.ant-input-number {
  width: 100%;
  border: none;
  outline: none;
}
.ant-input-number-affix-wrapper > div.ant-input-number.ant-input-number-focused {
  box-shadow: none !important;
}
.ant-input-number-affix-wrapper input.ant-input-number-input {
  padding: 0;
}
.ant-input-number-affix-wrapper::before {
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.ant-input-number-affix-wrapper .ant-input-number-handler-wrap {
  z-index: 2;
}
.ant-input-number-prefix,
.ant-input-number-suffix {
  display: flex;
  flex: none;
  align-items: center;
  pointer-events: none;
}
.ant-input-number-prefix {
  -webkit-margin-end: 4px;
          margin-inline-end: 4px;
}
.ant-input-number-suffix {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  height: 100%;
  margin-right: 11px;
  margin-left: 4px;
}
.ant-input-number-group-wrapper .ant-input-number-affix-wrapper {
  width: 100%;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-error .ant-input-number-feedback-icon {
  color: #ff4d4f;
}
.ant-input-number-status-error .ant-input-number-prefix {
  color: #ff4d4f;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-feedback-icon {
  color: #ff4d4f;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-prefix {
  color: #ff4d4f;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-validating .ant-input-number-feedback-icon {
  display: inline-block;
  color: #030047;
}
.ant-input-number-affix-wrapper-status-success .ant-input-number-feedback-icon {
  color: #52c41a;
  -webkit-animation-name: diffZoomIn1 !important;
          animation-name: diffZoomIn1 !important;
}
.ant-input-number-group-wrapper-status-error .ant-input-number-group-addon {
  color: #ff4d4f;
  border-color: #ff4d4f;
}
.ant-input-number-group-wrapper-status-warning .ant-input-number-group-addon {
  color: #faad14;
  border-color: #faad14;
}
.ant-input-number {
  box-sizing: border-box;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  transition: all 0.3s;
  /* stylelint-disable-next-line selector-no-vendor-prefix */
  display: inline-block;
  width: 90px;
  margin: 0;
  padding: 0;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-input-number::-moz-placeholder {
  opacity: 1;
}
.ant-input-number:-ms-input-placeholder {
  color: #bfbfbf;
  -ms-user-select: none;
      user-select: none;
}
.ant-input-number::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-input-number:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-input-number:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number:hover {
  border-color: #130d54;
  border-right-width: 1px;
}
.ant-input-number:focus,
.ant-input-number-focused {
  border-color: #130d54;
  box-shadow: 0 0 0 2px rgba(3, 0, 71, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number-borderless,
.ant-input-number-borderless:hover,
.ant-input-number-borderless:focus,
.ant-input-number-borderless-focused,
.ant-input-number-borderless-disabled,
.ant-input-number-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input-number {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-number-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-number-lg input {
  font-size: 16px;
}
.ant-input-number-sm {
  padding: 0px 7px;
}
.ant-input-number-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-input-number-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.ant-input-number-group > [class*='col-'] {
  padding-right: 8px;
}
.ant-input-number-group > [class*='col-']:last-child {
  padding-right: 0;
}
.ant-input-number-group-addon,
.ant-input-number-group-wrap,
.ant-input-number-group > .ant-input-number {
  display: table-cell;
}
.ant-input-number-group-addon:not(:first-child):not(:last-child),
.ant-input-number-group-wrap:not(:first-child):not(:last-child),
.ant-input-number-group > .ant-input-number:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.ant-input-number-group-addon,
.ant-input-number-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.ant-input-number-group-wrap > * {
  display: block !important;
}
.ant-input-number-group .ant-input-number {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.ant-input-number-group .ant-input-number:focus {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-number-group .ant-input-number:hover {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-search-with-button .ant-input-number-group .ant-input-number:hover {
  z-index: 0;
}
.ant-input-number-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}
.ant-input-number-group-addon .ant-select {
  margin: -5px -11px;
}
.ant-input-number-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
.ant-input-number-group-addon .ant-select-open .ant-select-selector,
.ant-input-number-group-addon .ant-select-focused .ant-select-selector {
  color: #030047;
}
.ant-input-number-group-addon .ant-cascader-picker {
  margin: -9px -12px;
  background-color: transparent;
}
.ant-input-number-group-addon .ant-cascader-picker .ant-cascader-input {
  text-align: left;
  border: 0;
  box-shadow: none;
}
.ant-input-number-group > .ant-input-number:first-child,
.ant-input-number-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-number-group > .ant-input-number:first-child .ant-select .ant-select-selector,
.ant-input-number-group-addon:first-child .ant-select .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-number-group > .ant-input-number-affix-wrapper:not(:first-child) .ant-input-number {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-group > .ant-input-number-affix-wrapper:not(:last-child) .ant-input-number {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-number-group-addon:first-child {
  border-right: 0;
}
.ant-input-number-group-addon:last-child {
  border-left: 0;
}
.ant-input-number-group > .ant-input-number:last-child,
.ant-input-number-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-group > .ant-input-number:last-child .ant-select .ant-select-selector,
.ant-input-number-group-addon:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-group-lg .ant-input-number,
.ant-input-number-group-lg > .ant-input-number-group-addon {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-number-group-lg .ant-input-number input,
.ant-input-number-group-lg > .ant-input-number-group-addon input {
  font-size: 16px;
}
.ant-input-number-group-sm .ant-input-number,
.ant-input-number-group-sm > .ant-input-number-group-addon {
  padding: 0px 7px;
}
.ant-input-number-group-lg .ant-select-single .ant-select-selector {
  height: 40px;
}
.ant-input-number-group-sm .ant-select-single .ant-select-selector {
  height: 24px;
}
.ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-search .ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child) {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-input-number-group .ant-input-number-affix-wrapper:not(:first-child),
.ant-input-search .ant-input-number-group .ant-input-number-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact {
  display: block;
}
.ant-input-number-group.ant-input-number-group-compact::before {
  display: table;
  content: '';
}
.ant-input-number-group.ant-input-number-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-number-group.ant-input-number-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-number-group.ant-input-number-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.ant-input-number-group.ant-input-number-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-number-group.ant-input-number-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-number-group.ant-input-number-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number-affix-wrapper {
  display: inline-flex;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-picker-range {
  display: inline-flex;
}
.ant-input-number-group.ant-input-number-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-number {
  float: none;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector,
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker .ant-input,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-group-wrapper .ant-input {
  border-right-width: 1px;
  border-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector:hover,
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input:hover,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker .ant-input:hover,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-group-wrapper .ant-input:hover {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector:focus,
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input:focus,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker .ant-input:focus,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-group-wrapper .ant-input:focus {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select-focused {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-arrow {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > *:first-child,
.ant-input-number-group.ant-input-number-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-input-number-group.ant-input-number-group-compact > *:last-child,
.ant-input-number-group.ant-input-number-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-right-width: 1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper {
  margin-left: -1px;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
  border-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 2px 0 0 2px;
}
.ant-input-number-group-wrapper {
  display: inline-block;
  text-align: start;
  vertical-align: top;
}
.ant-input-number-handler {
  position: relative;
  display: block;
  width: 100%;
  height: 50%;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  line-height: 0;
  text-align: center;
  border-left: 1px solid #d9d9d9;
  transition: all 0.1s linear;
}
.ant-input-number-handler:active {
  background: #f4f4f4;
}
.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #130d54;
}
.ant-input-number-handler-up-inner,
.ant-input-number-handler-down-inner {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  width: 12px;
  height: 12px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 12px;
  transition: all 0.1s linear;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-input-number-handler-up-inner > *,
.ant-input-number-handler-down-inner > * {
  line-height: 1;
}
.ant-input-number-handler-up-inner svg,
.ant-input-number-handler-down-inner svg {
  display: inline-block;
}
.ant-input-number-handler-up-inner::before,
.ant-input-number-handler-down-inner::before {
  display: none;
}
.ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon {
  display: block;
}
.ant-input-number:hover {
  border-color: #130d54;
  border-right-width: 1px;
}
.ant-input-number:hover + .ant-form-item-children-icon {
  opacity: 0;
  transition: opacity 0.24s linear 0.24s;
}
.ant-input-number-focused {
  border-color: #130d54;
  box-shadow: 0 0 0 2px rgba(3, 0, 71, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number-disabled .ant-input-number-input {
  cursor: not-allowed;
}
.ant-input-number-disabled .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-readonly .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-input {
  width: 100%;
  height: 30px;
  padding: 0 11px;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 2px;
  outline: 0;
  transition: all 0.3s linear;
  -webkit-appearance: textfield !important;
     -moz-appearance: textfield !important;
          appearance: textfield !important;
  /* stylelint-disable-next-line selector-no-vendor-prefix */
}
.ant-input-number-input::-moz-placeholder {
  opacity: 1;
}
.ant-input-number-input:-ms-input-placeholder {
  color: #bfbfbf;
  -ms-user-select: none;
      user-select: none;
}
.ant-input-number-input::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-input-number-input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number-input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-input-number-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number-input[type='number']::-webkit-inner-spin-button,
.ant-input-number-input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  appearance: none;
}
.ant-input-number-lg {
  padding: 0;
  font-size: 16px;
}
.ant-input-number-lg input {
  height: 38px;
}
.ant-input-number-sm {
  padding: 0;
}
.ant-input-number-sm input {
  height: 22px;
  padding: 0 7px;
}
.ant-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  border-radius: 0 2px 2px 0;
  opacity: 0;
  transition: opacity 0.24s linear 0.1s;
}
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: auto;
  margin-right: 0;
  font-size: 7px;
}
.ant-input-number-borderless .ant-input-number-handler-wrap {
  border-left-width: 0;
}
.ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: 40%;
}
.ant-input-number:hover .ant-input-number-handler-wrap,
.ant-input-number-focused .ant-input-number-handler-wrap {
  opacity: 1;
}
.ant-input-number-handler-up {
  border-top-right-radius: 2px;
  cursor: pointer;
}
.ant-input-number-handler-up-inner {
  top: 50%;
  margin-top: -5px;
  text-align: center;
}
.ant-input-number-handler-up:hover {
  height: 60% !important;
}
.ant-input-number-handler-down {
  top: 0;
  border-top: 1px solid #d9d9d9;
  border-bottom-right-radius: 2px;
  cursor: pointer;
}
.ant-input-number-handler-down-inner {
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
}
.ant-input-number-handler-down:hover {
  height: 60% !important;
}
.ant-input-number-borderless .ant-input-number-handler-down {
  border-top-width: 0;
}
.ant-input-number-handler-up-disabled,
.ant-input-number-handler-down-disabled {
  cursor: not-allowed;
}
.ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
  color: rgba(0, 0, 0, 0.25);
}
.ant-input-number-borderless {
  box-shadow: none;
}
.ant-input-number-out-of-range input {
  color: #ff4d4f;
}
.ant-input-number-rtl {
  direction: rtl;
}
.ant-input-number-rtl .ant-input-number-handler {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
}
.ant-input-number-rtl .ant-input-number-handler-wrap {
  right: auto;
  left: 0;
}
.ant-input-number-rtl.ant-input-number-borderless .ant-input-number-handler-wrap {
  border-right-width: 0;
}
.ant-input-number-rtl .ant-input-number-handler-up {
  border-top-right-radius: 0;
}
.ant-input-number-rtl .ant-input-number-handler-down {
  border-bottom-right-radius: 0;
}
.ant-input-number-rtl .ant-input-number-input {
  direction: ltr;
  text-align: right;
}

